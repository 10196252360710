<template>
    <nav-menu v-if="show"></nav-menu>
    <router-view />
    <!--<nav-footer></nav-footer>-->
    <!--<com-footer></com-footer>-->
</template>

<head>
    <meta name="HandheldFriendly" content="true" />
    <meta name="viewport" content="width=device-width, user-scalable=no" />
    <meta name="facebook-domain-verification" content="1u1roqy0c1dwh7ctdr1csh0aedlezn" />
    <script src="" async></script>
</head>


<script>
    import NavMenu from './components/NavMenu.vue';
    import axios from 'axios';
    //import NavFooter from './components/Footer.vue';

    export default {
        name: 'App',
        data() {
            return {
                show: true,
            }
        },
        setup() {
            // Add jive chat script 
            const jivoScript = document.createElement("script");
            jivoScript.setAttribute(
                "src",
                "//code.jivosite.com/widget/QfPJ6KxnU7"
            );
            jivoScript.async = true;
            document.head.appendChild(jivoScript);
            // End add jive chat script 
        },
        components: {
            NavMenu,
            //NavFooter
        },
        methods:{
            checkUser() {



                //localStorage.setItem('simpleUser', 'parkot3@gmail.com');
                //localStorage.removeItem('simpleUser');
                var tempUser = localStorage.getItem("simpleUser");
                if (tempUser === null) {

                    this.$router.push({ name: 'NotAllow' });
                    this.show = false;
                }
                else {

                    // Check if not exist user, then delete it from local storage
                    axios.post('/api/user/checkuser', {
                        email: tempUser,
                    }).then((response) => {
                        if (!response.data) {
                            localStorage.removeItem("simpleUser");
                            this.$router.push({ name: 'NotAllow' });
                            this.show = false;
                        }
                        else {
                            this.show = true;
                        }
                    })
                }
                //console.log("Mounted!");
                //if (this.$route.name == 'NotAllow') {
                //    this.show = false;
                //}
            },
        },
        mounted() {
            this.checkUser();
        },
        watch: {
            '$route.query.menu': function () {
                if (this.$route.query.menu == '1') {
                    this.show = true;
                    this.$router.push({ name: 'Home'});
                }
            }
        },
        computed() {
            //console.log("computed!");
        }
    }
</script>

<style>

        @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,300;0,500;1,400&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;1,300&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
        /*@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');*/
        @import url('https://fonts.googleapis.com/css2?family=BioRhyme:wght@300;400;700;800&display=swap');

        /*** Reset File ***/

    @font-face {
        font-family: 'Bebas Neue';
        src: url('./fonts/BebasNeueRegular.woff2') format('woff2'), url('./fonts/BebasNeueRegular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Bebas Neue';
        src: url('./fonts/BebasNeueBold.woff2') format('woff2'), url('./fonts/BebasNeueBold.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }



    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

        blockquote:before, blockquote:after,
        q:before, q:after {
            content: '';
            content: none;
        }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

        /*** End reset files ***/
    b {
        font-weight:bold;
    }
    body {
        font-family: 'Ubuntu Condensed';
        font-family: 'Oswald';
        font-family: 'Bebas Neue';
    }

        body.home {
            /*background-image: url('../src/assets/bg-2.png');*/
            background-image: url('../src/assets/line.jpg');
            background-size: cover;
            background-repeat: repeat-y;
            background-size: 100%;
        }

        body.black-style {
            color: black;
        }

        body.yellow-bg {
            background-color: #ffc501;
        }

        body.green-bg {
            background-color: #017f8a;
        }

    .green-color {
        color: #017f8a;
    }

    .yellow {
        color: #ffc501!important;
    }

    body.yellow-bg-line {
        background-image: url('../src/assets/bg-yellow-line.svg');
        background-repeat: repeat-y;
        background-size: 150px;
        background-position: right;
    }
    .display-none {
        display:none;
    }
    header.main-header {
        height: 100px;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
    }

    .black-style header {
        background-color: #fff;
    }

    main {
        margin-top: 100px;
    }

    .h1 {
        text-align: center;
        font-size: 2em;
        font-weight: 500;
    }

    .h1-2 {
        font-size: 5em;
    }

    .h1-3 {
        letter-spacing: 7px;
        margin-bottom: 20px;
    }

    .decor-header {
        margin-top: 10%;
    }

    .white-style h1, .white-style .h1 {
        color: #fff;
    }

    .white-style .content-text {
        color: #fff;
        margin-bottom: 5%;
    }

    a:hover {
        text-decoration: none;
        color: black;
    }

    a {
        color: black;
    }

    .white-style a:hover {
        text-decoration: underline;
        color: white;
    }

    .white-style a {
        color: white;
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .left-side {
    }

    .right-side {
    }
    p {
        line-height:24px;
    }

    
    /** input effect **/
    .input_container {
        display: inline-block;
        text-align: center;
        width: 80%;
        margin-top: 20px;
    }

    .awsome_input {
        padding: 5px 10px;
        border: none;
        background: transparent;
        display: block;
        font-size: 3em;
        width: 100%;
    }

    .awsome_input_border {
        display: inline-block;
        width: 100%;
        height: 2px;
        background: #000;
        position: relative;
        top: 3px;
        -webkit-transition: all ease-in-out .15s;
        -o-transition: all ease-in-out .15s;
        transition: all ease-in-out .15s;
    }

    .awsome_input:hover,
    .awsome_input:active,
    .awsome_input:focus {
        outline: none;
    }
        /*.awsome_input:hover+.awsome_input_border,*/
        .awsome_input:active + .awsome_input_border,
        .awsome_input:focus + .awsome_input_border {
            width: 0;
        }
    /** end input effect **/
    .social {
        position: absolute;
        bottom: 50px;
        left: 40px;
        width:30px;
    }

    .fixed {
        position: fixed;
    }

    .social-right {
        left: initial;
        right: 40px;
    }

    .social li {
        margin-bottom: 20px;
    }

    .contact-social-frame .social i {
        color: #fff;
    }

    .contact-social-frame .social {
        bottom: initial;
    }

    .logo {
        position: absolute;
        left: 30px;
        top: 30px;
    }

        .logo img {
            width: 60px;
        }

    .left-menu {
        position: absolute;
        right: 30px;
        top: 30px;
    }

        .left-menu li {
            display: inline-block;
            margin-left: 10px;
        }

    .text-1 {
        font-size: 13em;
        letter-spacing: 0.35em;
    }

        .text-1 span {
            letter-spacing: 0em;
        }

    .text-2 {
        font-size: 2.9em;
        letter-spacing: 0.16em;
        text-align: right;
        position: relative;
    }

    .text-3 {
        font-size: 1em;
        letter-spacing: 4px;
        margin-bottom: 15px;
    }

    .text-4 {
        position: relative;
        z-index: 3;
        display: inline-block;
        padding-left: 35px;
    }

    .text-5 {
        position: relative;
        z-index: 3;
        display: inline-block;
        padding-left: 10px;
        font-size: 2em;
        letter-spacing: 20px;
    }

    .about-line-paragraph {
        position: relative;
        margin-bottom: 15px;
    }

    .text-line-fill {
        background-color: white;
        width: 100%;
        height: 6px;
        top: 50%;
        margin-top: -3px;
        position: absolute;
        z-index: 1;
    }

    .content-text {
        font-family: Ubuntu;
        margin: 5% auto 0 auto;
        width: 50%;
        text-align: center;
    }

    .content-categories {
        font-family: Ubuntu;
        margin: 5% auto 0 auto;
        width: 80%;
        text-align: left;
        font-size: 2em;
        text-transform: uppercase;
    }

    .content-elements {
        text-transform: uppercase;
        margin: 10% auto 0 auto;
        line-height:72px;
    }

        .content-elements a {
            transition: color 0.3s ease;
        }

            .content-elements a:hover {
                /*color: #ffc107;*/
            }

    .content-elements {
        text-transform: uppercase;
        font-size: 3em;
        font-family: "Ubuntu Condensed";
    }

        .content-elements span.space {
            font-family: Arial, Helvetica, sans-serif;
            display: inline-block;
            margin: 0 11px 0 10px;
        }


    .menu-left-fixed {
        background-color: #ffc501;
        position: fixed;
        left: 0;
        top: 0;
        padding: 5%;
        font-family: 'Ubuntu Condensed';
        text-transform: uppercase;
        color: white;
        min-height: 300px;
        z-index:999;

    }

    .menu-left-fixed-button {
        background-color: #ffc501;
        position: fixed;
        left: 0;
        top: 50%;
        padding: 138px 5px 138px 5px;
        font-family: 'Ubuntu Condensed';
        text-transform: uppercase;
        color: white;
        display: inline-block;
        width: 54px;
        cursor: pointer;
        height: 300px;
        margin-top: -150px;
    }

        .menu-left-fixed-button div {
            -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            position: absolute;
            top: 50%;
            left: 50%;
            white-space: nowrap;
        }

    .green-bg-text {
        background-color: #017f8a!important;
    }

    .menu-left-fixed li {
        margin-bottom: 18px;
    }

    .left-side, .right-side {
        position: absolute;
        top: 30%;
        color: #fff;
    }

    .left-side-about, .right-side-about {
        top: 20%;
    }

    .left-side {
        left: 10%;
    }

    .right-side {
        right: 10%;
    }

    .text-about {
        font-family: 'Ubuntu Condensed';
        line-height: 1.4em;
        background-color: #017f8a;
        padding: 0 0 50px 40px;
        font-size: 1.5em;
        font-weight: 100;
    }

        .text-about p {
            margin-bottom: 1em;
            line-height:34px;
        }

    .button-home {
        border: 1px solid white;
        width: 100px;
        height: 40px;
        display: inline-block;
        text-align: center;
        cursor:pointer;
        position:relative;
    }

        .button-home img {
            padding: 15px 10px;
            width: 90%;
        }

    .text-align-left {
        text-align: left;
    }

    .text-align-right {
        text-align: right;
    }

    .float-right {
        float: right;
    }

    .float-left {
        float: left;
    }

    .home-text-border-bottom {
        height: 3px;
        width: 100%;
    }

    .home-text-border-bottom-green-bg {
        background-color: #049ca4;
    }

    .home-text-border-bottom-orange-bg {
        background-color: #ffdb61;
    }

    .home-text-border-bottom-green {
        color: #049ca4;
    }

    .home-text-border-bottom-orange {
        color: #ffdb61;
    }

    .bg-color-green-bg {
        background-color: #017f8a;
    }

    .bg-color-orange-bg {
        background-color: #ffc500;
    }

    .home-frame-button {
        margin: 20% 0 40px 0;
    }

    .content-frame {
        width: 70%;
        margin: 0 auto;
    }

    .contact-image {
        width: 200px;
        margin: 3% auto;
    }

        .contact-image img {
            width: 100%;
        }

    .contact-bottom-text {
        background-color: #017f89;
        color: #fff;
        margin: 3% auto;
        width: 75%;
        text-align: center;
        padding: 4%;
    }

    .contact-text-1 {
        font-size: 5em;
        letter-spacing: 8px;
        font-family: 'Abril Fatface', cursive;
        line-height: 1em;
    }

    .contact-text-2 {
        font-size: 4em;
        letter-spacing: 8px;
        font-family: 'Abril Fatface', cursive;
    }

    .contact-text-3 {
        text-align: left;
        font-family: 'Ubuntu Condensed';
        font-size: 1.5em;
        line-height: 0.8em;
    }

    .contact-text-4 {
        text-align: left;
        font-family: 'Ubuntu Condensed';
        font-size: 1.5em;
        line-height: 0.8em;
    }

    .contact-social-frame {
        margin-top: 10%;
    }

    .agreement-row {
        margin: 10% 0 10% 0;
    }

    .contact-send-button {
        font-size: 3em;
    }

        .contact-send-button::after {
            display: inline-block;
            content: ' ';
            background-image: url('../src/assets/contact-button-narrow.svg');
            background-size: 100% 100%;
            height: 38px;
            width: 58px;
            margin-left: 30px;
        }

    .decor-image-frame {
        margin-bottom: 40px;
        width: 100%;
    }

    .decor-image-subframe {
        width: 600px;
        position: relative;
        display:inline-block;
    }

    .decor-image-description {
        text-align: center;
        padding-top: 30px;
        font-family: 'Ubuntu Condensed';
        font-size: 1.4em;
    }

        .decor-image-description a {
            transition: color 0.3s;
        }

            .decor-image-description a:hover {
                color: #ffc500;
            }

    .decor-item-subheader {
        color: #ffc500;
        letter-spacing: 4px;
        font-size: 2.5em;
    }

        .decor-item-subheader span {
            color: gray;
        }
            .decor-item-subheader span.slash-number {
                color: #ffc500;
            }

    .content-categories a:hover {
        text-decoration: none;
        color: white;
    }

    .content-categories a, .content-categories span {
        color: #4fafba;
    }

    .content-prop {
        margin: 10% auto 0 auto;
        width: 70%;
        color: white;
    }

    .prop-header {
        max-width: 30%;
    }

    .newsletter {
        background-color: white;
        text-align: center;
        padding: 10% 0;
        margin-bottom: 10%;
        text-transform: uppercase;
        color: black;
        position: relative;
    }

    .text-8 {
        font-size: 5.3em;
        line-height: 1em;
        font-family: 'Bebas Neue';
    }

    .text-9 {
        letter-spacing: 3px;
        font-size: 2em;
        line-height: 3em;
    }

        .text-9 a, .text-9 a:hover {
            color: black;
        }

        .text-9 a {
            text-decoration: underline;
        }
    /* button close */
    #mdiv {
        width: 50px;
        height: 50px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 50%;
        padding: 9px;
        position: absolute;
        right: -28px;
        top: -28px;
        cursor: pointer;
        z-index:2;
    }
    .no-border {
        border:0!important;
    }

    .mdiv {
        height: 32px;
        width: 1px;
        margin-left: 15px;
        background-color: white;
        transform: rotate(45deg);
        Z-index: 1;
    }

    .md {
        height: 32px;
        width: 1px;
        background-color: white;
        transform: rotate(90deg);
        Z-index: 2;
    }
    #mdiv.black-close{
        border-color: black;
    }
    .black-close .mdiv, .black-close .md {
        background-color: black;
    }
    #mdiv.orange-close {
        border-color: #ffc107;
    }

    .orange-close .mdiv, .orange-close .md {
        background-color: #ffc107;
    }
    #mdiv.green-close {
        border-color: #17a2b8;
    }

    .green-close .mdiv, .green-close .md {
        background-color: #17a2b8;
    }
    #mdiv.white-close {
        border-color: white;
    }

    .white-close .mdiv, .white-close .md {
        background-color: white;
    }
    /* end button close */
    /* check box  */
    input[type=checkbox] {
        display: none;
    }
        /* 
  - Style each label that is directly after the input
  - position: relative; will ensure that any position: absolute children will position themselves in relation to it
  */

        input[type=checkbox] + label {
            position: relative;
            background: url(../src/assets/checkbox_.svg) no-repeat;
            height: 50px;
            width: 50px;
            display: block;
            border-radius: 50%;
            transition: border 0.4s;
            border: solid 0px #FFF;
            cursor: pointer;
        }
            /* Provide a border when hovered and when the checkbox before it is checked */

            input[type=checkbox] + label:hover,
            input[type=checkbox]:checked + label {
                border: solid 0.5px #000;
                /* Soften the jagged edge */
            }
                /* 
  - Create a pseudo element :after when checked and provide a tick
  - Center the content
  */

                input[type=checkbox]:checked + label:after {
                    /*content: '\2714';*/
                    content: ' ';
                    /*content is required, though it can be empty - content: '';*/
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    color: #000;
                    line-height: 1;
                    font-size: 18px;
                    text-align: center;
                    background-color: black;
                    border-radius: 50%;
                    transition: border 0.4s;
                }
    /* end check box */
    .copyright {
        width: 100%;
        text-align: center;
        font-family: BioRhyme;
        margin: 3% 0 2% 0;
        font-size: 0.8em;
    }
    /******************scroll*************************/
    .scroll-classic::-webkit-scrollbar {
        width: 2px;
    }

    /* Track */
    .scroll-classic::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .scroll-classic::-webkit-scrollbar-thumb {
        background: #d9dbdb;
    }

        /* Handle on hover */
        .scroll-classic::-webkit-scrollbar-thumb:hover {
            background: #919191;
        }

    /* Track */
    .scroll-classic::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /*
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }*/
    .scroll-classic::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    .scroll-classic {
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right:15px;
    }
    /************************end scroll************************/

    @media screen and (max-width: 1280px) {
        .scroll-classic {
            /*max-height: 300px;*/
            max-height: 200px;
        }
    }
    

    @media screen and (max-width: 580px) {
        .scroll-classic {
            max-height: 200px;
        }
        #menu li {
            display: block;
            width: 100%;
            margin: 0% 0 21% 0;
        }
        /** input effect **/
        .input_container {
            width: 100%;
        }
        .awsome_input {
            font-size: 2em;
        }
        .logo {
            position: absolute;
            left: 30px;
            top: 15px;
        }

            .logo img {
                width: 40px;
            }
    }
        /*
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 60px;
    }*/

    /* Calendar style */
    .vuejs3-datepicker__value {
        border: 0px !important;
        padding: 0px !important;
        padding-left:0px!important;
    }
    .vuejs3-datepicker__content {
        font-size: 30px !important;
        color: #787575;
    }
    .vuejs3-datepicker__icon {
        display:none!important;
    }
    .vuejs3-datepicker__calendar .cell.selected {
        background: #017f8a !important;
    }
    .vuejs3-datepicker__calendar-topbar {
        background-color: #017f8a !important;
    }
    /* End calendar style */
    .h2-custom {
        font-size:24px;
        font-weight:bold;
        margin-bottom:20px;
    }
    @media screen and (max-width: 580px) 
    {
        .vuejs3-datepicker__content {
            font-size: 21px !important;
        }
    }
</style>
