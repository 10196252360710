<template>
    <div class="login-frame-user-menu">
        <ul class="user-menu">
            <li><router-link :to="{ name: 'Partner' }"><!--{{user.firstname}}-->ΚΑΡΤΕΛΑ</router-link></li>
            <li @click="logout"><a>Logout</a></li>
        </ul>
    </div>
    <alert :messages = "errorsLocal"></alert>
</template>


<script>
    //import axios from 'axios'
    export default {
        name: 'UserMenu',
        props: {
            msg: String
        },
        data() {
            return {
                errorsLocal: []
            }
        },
        methods: {
            onCloseButton() {
                this.$emit('close-clicked')
            },
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        //this.$router.push('/login')
                        this.$router.push('/')
                        this.onCloseButton();
                    })
            }
        },
        computed: {
            user: function () {
                //console.log('12',this.$store.getters.user)
                return this.$store.getters.user
            },
            userName: function () {
                //console.log('10', this.$store.getters.userName)
                return this.$store.getters.userName
            },
            token: function () {
                //console.log('10', this.$store.getters.userName)
                return this.$store.getters.token
            }
        },
        //created() {
        //    this.$store.dispatch('getCartItems', this.getUID());
        //}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .login-frame-user-menu {
        background-color: white;
        padding: 5px 0;
        position: fixed;
        z-index: 999;
        top: 54px;
        right: 17px;
        width: 158px;
        box-shadow: -2px 1px 13px -3px rgb(0 0 0 / 98%);
    }

    .user-menu li{
        
    }
        .user-menu li:hover {
            background-color: #dbdbdb;
        }
        .user-menu li a {
            color: black;
            display: block;
            width: 100%;
            padding: 10px;
        }
    .user-menu li{
        cursor:pointer;
    }

    @media screen and (max-width: 580px) {
    }
</style>
