<template>
    <main>
        <h1 class="h1">{{user.fullname}}</h1>
        <div class="content-text content-text-partner">
            <div class="partner-menu">
                <!--<router-link :to="{ name: 'Partner' }"><span>ΠΡΟΪΟΝΤΑ</span></router-link>
        <router-link :to="{ name: 'PartnerRent' }"><span>ΕΝΟΙΚΙΑΣΕΙΣ</span></router-link>-->
                <PartnerMenu></PartnerMenu>
                <div class="partner-totals">
                    <div class="partner-totals-col">
                        ΣΥΝΟΛΟ ΕΙΔΟΥΣ
                        <div class="partner-totals-info">{{qty}}</div>
                    </div>
                    <div class="partner-totals-col">
                        ΣΥΝΟΛΙΚΗ ΑΞΙΑ ΤΩΝ ΠΡΟΪΟΝΤΩΝ
                        <div class="partner-totals-info">{{numberFormat(total)}} &euro;</div>
                    </div>
                    <div class="partner-totals-col">
                        ΣΥΝΟΛΙΚΗ ΠΟΣΌΤΗΤΑ ΤΩΝ ΠΡΟΪΟΝΤΩΝ
                        <div class="partner-totals-info">{{qtyTotal}}</div>
                    </div>
                </div>
            </div>

            <div class="content-product">
                <!--<input @input="onChange" @click="onChange" v-model="search" class="input-partner-search"
                       placeholder="Αναζήτηση - πληκτρολογήστε τουλάχιστον 3 χαρακτήρες" type="text" />-->
                <div class="product-frame">
                    <div class="product-item" v-for="product of products" v-bind:key="product">
                        <div @click="showModalPopup(product.id)" class="product-image"
                                :class="{ 'deleted-product': product.isdeleted }"
                                v-bind:id="product.id"
                             :style="{ backgroundImage: 'url(\'' + getPhoto(product.photo) + '\')' }">
                        </div>
                        <div class="product-description" alt="{{ product.title }}">
                            <b>{{ product.code }}</b> - {{ product.title }}
                        </div>
                        <div class="product-price" v-show="product.priceApozimiosis==0">
                            <b>{{ numberFormat(product.price) }} €</b> - Τεμάχια: {{product.qtyStarted}}
                        </div>
                        <div class="product-price" v-show="product.priceApozimiosis!=0">
                            <div class="left-product-cart">
                                ΕΝΟΙΚΙΑΣΗ <b>{{ numberFormat(product.price) }}€</b><br />
                                ΑΠΟΖΗΜΙΩΣΗ <b>{{ numberFormat(product.priceApozimiosis) }}€</b>
                            </div>
                            <div class="right-product-cart">
                                <span class="split-line"></span>
                                ΤΕΜΑΧΙΑ: {{product.qtyStarted}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pagination :total-pages="totalPages" :total="totalComponent" :per-page="perPage" :current-page="currentPage"
                        :has-more-pages="hasMorePages" @pagechanged="pageChange($event)">
            </pagination>

        </div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    //import '@ocrv/vue-tailwind-pagination/dist/style.css'
    //import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'

    import axios from 'axios'
    export default {
        name: 'Partner',
        props: {
            msg: String
        },
        //components: {
        //    VueTailwindPagination,
        //},
        data() {
            return {
                products: [],
                qty: 0,
                qtyTotal: 0,
                total: 0,
                currentPage: 1,
                perPage: 12,
                totalComponent: 16,
                totalPages: 0,
                search: '',
                timeout: null,
                hasMorePages: true

            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            async getData(pageNumber, searchString = '') {
                //console.log(this.user.id);
                await axios.get('/api/product/partner', {
                    params: {
                        categoryId: this.$route.params.id,
                        id: this.user.id,
                        page: pageNumber,
                        search: searchString,
                        pageSize: this.perPage
                    }
                }).then((response) => {
                    //console.log(response.data.items);
                    this.products = response.data.items;
                    this.qty = response.data.qty;
                    this.qtyTotal = response.data.qtyTotal;
                    this.total = response.data.total;
                    this.totalComponent = this.qty;
                    this.totalPages = response.data.totalPages;
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            pageChange(pageNumber) {
                this.currentPage = pageNumber;
                this.getData(pageNumber);
            },
            onChange() {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                var self = this
                this.timeout = setTimeout(function () {
                    self.reloadData()
                }, 1000);

            },
            reloadData() {
                if (this.search.length > 2) {
                    //console.log(this.search);
                    this.getData(0, this.search);
                }
                else if (this.search.length == 0) {
                    this.getData(0);
                }
            },
            showModalPopup(id) {
                //this.productId = this.getImage(id);
                //this.showModal = true;
                //this.$router.push({ name: 'Product', params: { id: this.$route.params.id }, query: { product_id: id, type: this.$route.query.type } })
                console.log(id);
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
            isLoggedIn: function () { return this.$store.getters.isLoggedIn },
            user: function () { return this.$store.getters.user }
        },
        watch: {
            '$route.params.id': function () {
                if (this.$route.params.id != undefined) {
                    this.currentPage = 1;
                    this.getData(this.currentPage);
                }
            }
        },
        mounted() {
            if (!this.isLoggedIn) this.$router.push('/');
            this.getData(this.currentPage);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .split-line {
        background-color: #008089;
        width: 2px;
        height: 28px;
        display: inline-block;
    }
    .left-product-cart {
        display: inline-block;
        width: 50%;
        text-align: right;
        line-height: 20px;
        padding-right: 5px;
    }

    .right-product-cart {
        display: inline-block;
        width: 50%;
        text-align: left;
        padding-right: 5px;
    }
    .input-partner-search {
        margin-top: 16px;
        padding: 10px;
        max-width: 100%;
        width: 500px;
        font-size: 18px;
        border: 1px solid #d4d4d4;
        border-radius: 10px;
    }
    input.input-partner-search:focus {
        outline: none !important;
        border-color: #719ECE;
        box-shadow: 0 0 10px #719ECE;
    }
    .partner-totals-info {
        font-weight: bold;
        margin: 10px 0 20px 0;
    }
    .partner-totals {
        text-align: left;
        margin: 60px auto 0;
        color: white;
        font-family: "Bebas Neue";
        font-size: 1.3em;
        letter-spacing: 2px;
        width:845px;
    }
    .partner-totals-col {
        display: inline-block;
        margin-right: 90px;
    }
    .partner-totals-col:last-child {
        margin-right: 0px;
    }
    .content-product {
        font-family: 'Ubuntu Condensed';
    }
    .partner-menu {
        background-color: #00828b;
        padding: 80px 0 40px 0;
    }
    .content-text-partner {
        width: 70%;
        margin-top: 2%;
    }
    .product-image {
        width: 100%;
        height: 0px;
        padding-bottom: 86.25%;
        background-color: gray;
        position: relative;
        cursor: pointer;
        /*background-size: auto 100%;*/
        background-size: cover;
        background-position: center center;
    }
    .deleted-product {
        opacity: 0.4;
        position:relative;
    }
        .deleted-product::before {
            /*content: url('../src/assets/deleted.png');*/
            content: '';
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            width: 100px;
            height: 100px;
            background-image: url('../assets/deleted.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
        }
    .product-frame {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        margin-top: 40px;
    }

    .product-item {
        width: 23%;
        margin: 2% 1%;
        height: auto;
        position: relative;
        font-family: 'Bebas Neue';
        letter-spacing: 1px;
    }

    .product-description {
        margin: 15px 0 0 0;
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        margin-bottom: 5px;
        position: relative;
        text-transform: uppercase;
        min-height: 65px;
        line-height: 20px;
        overflow: visible;
    }
    @media screen and (max-width: 580px) {
        .product-item {
            width: 48%;
        }
        .partner-totals {
            width: 100%;
            padding-left:30px;
        }
        .content-text-partner {
            width: 80%;
            margin-top: 2%;
        }
        .partner-menu{
            width:100%;
            padding: 80px 0 40px 0;
        }
    }
</style>
