<template>
    <main>
        <h1 class="h1">STRUCTURAL ELEMENTS</h1>
        <div class="content-text content-elements">
            <!--<router-link :to="{ name: 'Product' }">Παραθυρο</router-link><span>/</span>
            <router-link :to="{ name: 'Product' }">Πορτα</router-link><span>/</span>
            <router-link :to="{ name: 'Product' }">Γωνια</router-link><span>/</span>
            <router-link :to="{ name: 'Product' }">Τοιχος</router-link><span>/</span>
            <router-link :to="{ name: 'Product' }">Παγκος</router-link><span>/</span>
            <router-link :to="{ name: 'Product' }">Φραχτης</router-link><span>/</span>-->
            <span v-for="(item, key) of dataArray" v-bind:key="item">
                <!--<router-link :to="{ name: 'ElementItem', params: { id: item.id } , query: { type: 'elements' }}">{{ item.title }}</router-link>-->
                <router-link :to="{ name: 'ElementItem', params: { id: item.id }}">{{ item.title }}</router-link>
                <span v-if="key+1 != dataArray.length" class="space">/</span>
            </span>
        </div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    import axios from 'axios'
    export default {
        name: 'Elements',
        props: {
            msg: String
        },
        beforeCreate: function () {
            document.body.className = ' white-style yellow-bg';
        },
        data() {
            return {
                dataArray: [{ title: "ΚΟΥΖΙΝΕΣ", id: 1 }, { title: "ΔΙΑΦΟΡΑ", id: 2 }]
            }
        },
        methods: {
            getData() {
                axios.get('/api/category', {
                    params: {
                        id: this.$route.params.id
                    }
                }).then((response) => {
                    this.dataArray = response.data;
                })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        //mounted() {
        //    this.getData();
        //}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    @media screen and (max-width: 580px) {
        .content-elements {
            width: 80%;
            font-size: 2em;
            line-height: 52px;
        }
            .content-elements span.space {
                margin: 0 6px 0 5px;
            }
    }
</style>
