<template>
    <div class="partner-menu-list">
        <router-link :to="{ name: 'Partner' }"><span>ΠΡΟΪΟΝΤΑ</span></router-link>
        <router-link :to="{ name: 'PartnerRent' }"><span>ΕΝΟΙΚΙΑΣΕΙΣ</span></router-link>
    </div>
</template>


<script>
    export default {
        name: 'PartnerMenu',
        props: {
            msg: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .partner-menu-list span {
        color: white;
        font-family: "Bebas Neue";
        font-size: 2em;
        font-weight: bold;
        letter-spacing: 2px;
        margin: 0 40px;
    }

    .partner-menu-list a:hover {
        text-decoration: underline;
        color: white;
    }
    .router-link-active {
        text-decoration: underline;
        color: white;
    }
    @media screen and (max-width: 580px) {

        .partner-menu-list span {
            letter-spacing: 2px;
            font-size: 1.7em;
            margin: 0 10px;
        }
    }
</style>
