<template>
    <main>
        <h1 class="h1">
            <router-link :to="{ name: 'Roll' }">ROLL</router-link> /
            <router-link :to="{ name: 'Decor' }">DECOR</router-link>
            / {{result.title}}
        </h1>
        <div class="h1 decor-item-subheader">
            <span v-for="(item, key) in allResult.list" v-bind:key="item">
                <router-link :to="{ name: 'DecorItem', params: { id: item.id }  }">{{formatNumberZero(key+1)}}</router-link>
                <span class="slash-number" v-if="key+1 != allResult.list.length"> / </span>
            </span>
        </div>

        <div v-for="image of result.images" v-bind:key="image">
            <div class="decor-image-frame" :class="getClass(image.imageType)">
                <div class="decor-item-image-subframe">
                    <!--<div class="decor-item-image" :style="{ backgroundImage: 'url(\'' + getProject2() + '\')' }">-->
                    <div class="decor-item-image-fr ">
                        <img :src="getImage(image.src)" />
                        <div class="decor-item-image-description ">
                            {{getText(image.imageType)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>


<script>
    export default {
        name: 'Decor-Item',
        props: {
            msg: String
        },
        data() {
            return {
                result: [],
                allResult:[]
            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            getData() {

                this.allResult = {
                    list: [{ id: '1', title: "ΑΧΥΡΩΝΑΣ", images: [{ imageType: 1, src: "axironas-size.jpg" }, { imageType: 4, src: "axironas.jpg" }] },
                        { id: '2', title: "HOUSE", images: [{ imageType: 4, src: "project2.jpg" }] },
                        { id: '3', title: "HOUSE", images: [{ imageType: 4, src: "project2-1.jpg" }] }
                            ]
                };


                for (const item of this.allResult.list) {
                    if (item.id === this.$route.params.id) {
                        this.result = item
                    }
                }   
            },
            getClass(imageType) {
                return 'decor-item-image-' + imageType;
            },
            getText(imageType) {
                switch (imageType) {
                    case 1:
                        return 'TELARO';
                    case 2:
                        return 'TELARO';
                    case 3:
                        return '3D';
                    default:
                        return 'FINAL';
                }
            }
        },
        watch: {
            '$route.params.id': function () {
                this.getData();
            }
        },
        mounted() {
            this.getData();

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .decor-item-image-fr {
        max-width: 800px;
        /*height: 400px;*/
        margin:0 auto;
        background-size: cover;
        background-position: center center;
        position:relative;
    }

    .content-decor {
        font-family: Ubuntu;
        margin: 1% auto 0 auto;
        width: 90%;
    }

    .content-decor-item {
        font-family: Ubuntu;
        margin: 5% auto 0 auto;
        width: 60%;
        text-align:center;
    }

    .decor-item-image-fr img{
        width:800px;
    }

    .decor-item-image-description {
        font-size: 8em;
        color: #ffc500;
        position: absolute;
        letter-spacing:0.2em;
        font-family: 'Bebas Neue';
        -webkit-text-stroke: 1px #ffc500;
        -webkit-text-fill-color: transparent;
    }

    .decor-item-image-1 .decor-item-image-description {
        top: -116px;
        right: -165px;
        text-align: right;
    }
    .decor-item-image-1 {
        margin-top: 180px;
    }

    .decor-item-image-4 {
        margin-bottom: 180px;
    }

        .decor-item-image-4 .decor-item-image-description {
            bottom: -129px;
            left: -112px;
        }
    .decor-item-image-5 {
        margin-bottom: 0px;
        left:0px;
    }

    .decor-item-image-subframe {
        position: relative;
    }
    @media screen and (max-width: 580px) {
        .decor-item-image-fr img {
            width: 100%;
        }
        .decor-item-image-4 .decor-item-image-description {
            left: 10px;
        }
        .decor-item-image-1 .decor-item-image-description {
            right: 10px;
        }
    }
</style>
