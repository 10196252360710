<!--https://codepen.io/abhisheksarmah/pen/GRJyXpG-->
<template>
    <div class="flex justify-end pagination-custom-frame">
        <!--<ul class="pagination-custom bg-white p-2 shadow-sm rounded">-->
        <ul class="pagination-custom bg-white p-2  rounded">
            <li class="pagination-custom-item">
                <span class="rounded-l rounded-sm border border-gray-100 px-3 py-2 cursor-not-allowed no-underline text-gray-600 h-10"
                      v-if="isInFirstPage">&laquo;</span>
                <a v-else
                   @click.prevent="onClickFirstPage"
                   class="rounded-l rounded-sm border-t border-b border-l border-gray-100 px-3 py-2 text-gray-600 hover:bg-gray-100 no-underline"
                   href="#"
                   role="button"
                   rel="prev">
                    &laquo;
                </a>
            </li>

            <li class="pagination-custom-item pagination-previous">
                <button type="button"
                        @click="onClickPreviousPage"
                        :disabled="isInFirstPage"
                        aria-label="Go to previous page"
                        class="rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline mx-2 text-sm"
                        :class="{'cursor-not-allowed': isInFirstPage}">
                    Previous
                </button>
            </li>

            <li v-for="page in pages"
                class="pagination-custom-item"
                :key="page.name">
                <span class="rounded-sm border border-blue-100 px-3 py-2 bg-blue-100 no-underline text-blue-500 cursor-not-allowed mx-2"
                      v-if="isPageActive(page.name)">{{ page.name }}</span>
                <a class="rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline mx-2"
                   href="#"
                   v-else
                   @click.prevent="onClickPage(page.name)"
                   role="button">{{ page.name }}</a>
                <!-- <button
                type="button"
                @click="onClickPage(page.name)"
                :disabled="page.isDisabled"
                :class="{ active: isPageActive(page.name) }"
            >{{ page.name }}</button> -->
            </li>

            <li class="pagination-custom-item pagination-next">
                <button type="button"
                        @click="onClickNextPage"
                        :disabled="isInLastPage"
                        aria-label="Go to next page"
                        class="rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline mx-2 text-sm"
                        :class="{'cursor-not-allowed': isInLastPage}">
                    Next
                </button>
            </li>

            <li class="pagination-custom-item">
                <!-- <button
                type="button"
                @click="onClickLastPage"
                :disabled="isInLastPage"
                aria-label="Go to last page"
            >Last</button> -->
                <a class="rounded-r rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline"
                   href="#"
                   @click.prevent="onClickLastPage"
                   rel="next"
                   role="button"
                   v-if="hasMorePages">&raquo;</a>
                <span class="rounded-r rounded-sm border border-gray-100 px-3 py-2 hover:bg-gray-100 text-gray-600 no-underline cursor-not-allowed"
                      v-else>&raquo;</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "pagination-custom",
        props: {
            maxVisibleButtons: {
                type: Number,
                required: false,
                default: 3
            },

            totalPages: {
                type: Number,
                required: true
            },

            total: {
                type: Number,
                required: true
            },

            perPage: {
                type: Number,
                required: true
            },

            currentPage: {
                type: Number,
                required: true
            },

            hasMorePages: {
                type: Boolean,
                required: true
            }
        },

        computed: {
            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }

                if (this.currentPage === this.totalPages) {
                    
                    if (this.totalPages < this.maxVisibleButtons) {
                        return this.totalPages - 1;
                    }
                    else {
                        return this.totalPages - this.maxVisibleButtons + 1;
                    }
                }

                return this.currentPage - 1;
            },
            endPage() {
                return Math.min(
                    this.startPage + this.maxVisibleButtons - 1,
                    this.totalPages
                );
            },
            pages() {
                const range = [];
                var startPage = this.startPage;


                for (let i = startPage; i <= this.endPage; i += 1) {
                    range.push({
                        name: i,
                        isDisabled: i === this.currentPage
                    });
                }

                return range;
            },
            isInFirstPage() {
                return this.currentPage === 1;
            },
            isInLastPage() {
                return this.currentPage === this.totalPages;
            }
        },

        methods: {
            onClickFirstPage() {
                this.$emit("pagechanged", 1);
                this.scrollTop();
            },
            onClickPreviousPage() {
                this.$emit("pagechanged", this.currentPage - 1);
                this.scrollTop();
            },
            onClickPage(page) {
                this.$emit("pagechanged", page);
                this.scrollTop();
            },
            onClickNextPage() {
                this.$emit("pagechanged", this.currentPage + 1);
                this.scrollTop();
            },
            onClickLastPage() {
                this.$emit("pagechanged", this.totalPages);
                this.scrollTop();
            },
            isPageActive(page) {
                return this.currentPage === page;
            },
            scrollTop() {
                this.scrollTopPage();
            }
        }
    };
</script>
<style>
    .pagination-custom-frame {
        margin:40px 0 40px 0;
    }
    .pagination-custom {
        list-style-type: none;
        text-align:center;
    }

    .pagination-custom-item {
        display: inline-block;
    }

    .active {
        @apply .border-t .border-b .border-l .border-blue-100 .px-3 .py-2 .bg-blue-100 .no-underline .text-blue-500 .text-sm;
    }

    .pagination-custom li{
        cursor:pointer;
    }

        .pagination-custom li span {
            background-color: #dee2e6;
        }
    @media screen and (max-width: 480px) {
        .pagination-previous, .pagination-next {
            display:none;
        }
    }
</style>