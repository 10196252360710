<template>
    <div class="login-frame tour-frame">

        <div id="mdiv" class="black-close" @click="onCloseButton">
            <div class="mdiv">
                <div class="md"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <p>LIVE TOUR</p>
                    </div>
                </div>
                <!--<div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="frame-button-radio-register">
                            <div class="frame-text-individual">
                                <input class="custom-radio" v-model="customerType" name="customerType" type="radio" id="individual" checked="checked" value="individual">
                                <label for="individual" class="text-register-radio"><span class="text-register">INDIVIDUAL</span></label>
                            </div>
                            <div class="frame-text-individual">
                                <input class="custom-radio" v-model="customerType" name="customerType" type="radio" id="company" value="company">
                                <label for="company" class="text-register-radio"><span class="text-register">COMPANY</span></label>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <input type="text" class="awsome_input" v-on:keyup.enter="register"
                                   v-model="firstname" placeholder="FIRST NAME*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <input type="text" class="awsome_input" v-on:keyup.enter="register"
                                   v-model="email" placeholder="E-MAIL*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="profession_label" for="tourTime">DATE</label>
                            <select v-model="typeLiveTourDate">
                                <option value="0" selected="selected">--Select Date--</option>
                                <option v-for="tourDate in tourDates"
                                        v-bind:key="tourDate"
                                        v-bind:value="{ id: tourDate.id, text: tourDate.name }">
                                    {{ tourDate.name }}
                                </option>
                            </select>
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="profession_label" for="tourTime">TIME</label>
                            <select v-model="typeLiveTourTime">
                                <option value="0" selected="selected">--Select Time--</option>
                                <option v-for="tourTime in tourTimes"
                                        v-bind:key="tourTime"
                                        v-bind:value="{ id: tourTime.id, text: tourTime.name }">
                                    {{ tourTime.name }}
                                </option>
                            </select>
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="profession_label" for="typeLiveTour">SPECIALTY</label>
                            <select v-model="typeLiveTour">
                                <option value="0" selected="selected">--Select Team or Zoom--</option>
                                <option value="Team">Team</option>
                                <option value="Zoom">Zoom</option>
                            </select>
                            <!--<select v-model="specialtySelected">
                                <option value="0" selected="selected">--Select SPECIALTY--</option>
                                <option v-for="specialty in specialties"
                                        v-bind:key="specialty"
                                        v-bind:value="{ id: specialty.id, text: specialty.name }">
                                    {{ specialty.name }}
                                </option>
                            </select>-->
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <a href="#">
                            <span @click="send" class="register-send-button">SEND</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert :messages="errorsLocal" :info="true"></alert>
</template>


<script>
    import axios from 'axios'
    export default {
        name: 'Register',
        props: {
            msg: String
        },
        data() {
            return {
                typeLiveTour: "0",
                typeLiveTourTime: "0",
                typeLiveTourDate: "0",
                errorsLocal: [],
                tourTimes: [],
                tourDates: []
            }
        },
        methods: {
            onCloseButton() {
                this.$emit('close-clicked')
            },
            send: function () {

                if (this.checkFields()) {
                    //alert(customerType);
                    axios.post('/api/tour/', {
                        firstname: this.firstname,
                        email: this.email,
                        tourDate: this.typeLiveTourDate.id,
                        tourTime: this.typeLiveTourTime.id,
                        tourType: this.typeLiveTour
                    }).then((response) => {
                        console.log(response);
                        if (response.data.message != "") {
                            //localStorage.setItem('user', JSON.stringify(response.data));
                            this.errorsLocal = [response.data.message];

                            setTimeout(() => {
                                this.onCloseButton();
                            }, "3000");


                        }
                        else {
                            //console.log("Login failed!");
                            this.errorsLocal = ["Please try again later!"];
                        }
                        //console.log(response.data.id);

                        //var test = JSON.parse(localStorage.getItem('user'));
                        //console.log(test.id);
                        //console.log(localStorage.getItem('user'));

                    })
                }

            },
            checkFields() {
                if (!this.firstname || !this.email) {
                    this.errorsLocal = ["All fields are required!"];
                    return false;
                }
                else if (this.typeLiveTour == "0") {
                    this.errorsLocal = ["Select type"];
                }
                else if (this.typeLiveTourDate == "0") {
                    this.errorsLocal = ["Select date"];
                }
                else if (this.typeLiveTourTime == "0") {
                    this.errorsLocal = ["Select time"];
                }
                else if (!this.validEmail(this.email)) {
                    this.errorsLocal = ["Check your email"];
                }
                else {
                    return true;
                }

            },
            bindData() {
                //this.specialties = [
                //    { id: 1, name: 'A' },
                //    { id: 2, name: 'B' },
                //    { id: 3, name: 'C' }
                //];
                axios.get('/api/tour/getTimes').then((response) => {
                    this.tourTimes = response.data.times;
                    this.tourDates = response.data.dates;
                })
            },
            register1() {
                this.$errors.push('Name required.');
                this.$errors = ['e', 'r', 'd'];
                //this.$refs.Error.test();
                //console.log(this.errors);
                //debugger;
                axios.post('/api/account/register', {
                    password: this.password,
                    username: this.email
                }).then((response) => {
                    console.log(response);
                    if (response.data != "") {
                        localStorage.setItem('user', JSON.stringify(response.data));
                        this.onCloseButton();
                    }
                    else {
                        console.log("Login failed!");
                        this.errorsLocal = ["Login failed!"];
                    }
                    //console.log(response.data.id);

                    //var test = JSON.parse(localStorage.getItem('user'));
                    //console.log(test.id);
                    //console.log(localStorage.getItem('user'));

                })
                //.catch(function (error) {
                //alert(error);
                //});
            }
        },
        mounted() {
            this.bindData();
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
        .tour-frame {

        top: 15% !important;
        z-index:9999!important;
    }
    .input_container select {
        font-size: 34px;
        width: 100%;
    }
    .awsome_input_border {
        height:1px;   
    }
    /* для элемента input c type="radio" */
    .custom-radio {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
        /* для элемента label связанного с .custom-radio */
        .custom-radio + label {
            display: inline-flex;
            align-items: center;
            user-select: none;
        }
            /* создание в label псевдоэлемента  before со следующими стилями */
            .custom-radio + label::before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 50%;
                margin-right: 0.5em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
            }
        /* стили при наведении курсора на радио */
        .custom-radio:not(:disabled):not(:checked) + label:hover::before {
            border-color: #000000;
        }
        /* стили для активной радиокнопки (при нажатии на неё) */
        .custom-radio:not(:disabled):active + label::before {
            background-color: #000000;
            border-color: #000000;
        }
        /* стили для радиокнопки, находящейся в фокусе */
        .custom-radio:focus + label::before {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
        /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
        .custom-radio:focus:not(:checked) + label::before {
            border-color: #80bdff;
        }
        /* стили для радиокнопки, находящейся в состоянии checked */
        .custom-radio:checked + label::before {
            border-color: #000000;
            background-color: #000000;
            /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");*/
        }
        /* стили для радиокнопки, находящейся в состоянии disabled */
        .custom-radio:disabled + label::before {
            background-color: #000000;
        }
    .text-register-radio {
        cursor:pointer;
    }
    .frame-button-radio-register {
        margin: 0 auto;
        width: 330px;
    }
    .frame-text-individual {
        width: 150px;
        display: inline-block;
    }
    .frame-text-individual:first-child {
        margin-right:30px;
    }
    .checkbox-group-button {
        float: left;
    }
    .text-register {
        font-size: 2em;
        font-weight:bold;
    }
    .input_container {
        width: 100%;
    }
    .login-frame {
        background-color: white;
        padding: 60px;
        position: fixed;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -300px;
        width: 600px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .login-frame p, .login-frame h1{
        color:black;
    }
        .login-frame .awsome_input {
            font-size: 2em;
        }
        .login-frame .contact-email {
            font-family: Roboto Condensed;
            font-weight:bold;
        }
    .register-send-button {
        margin-top: 40px;
        border: 1px solid black;
        display: inline-block;
        padding: 15px 40px 10px;
        color: black;
        font-size: 2em;
    }
    .login-header {
        font-size: 2em;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    @media screen and (max-width: 580px) {
        .input_container select{
            font-size: 24px;
        }
        .register-send-button{
            margin-bottom: 0px!important;
        }
        .register-send-button {
            margin-top: 29px;
            padding: 15px 40px 10px;
        }
    }
    @media screen and (max-width: 580px) {
        .tour-frame {
            top: 10%!important;
        }
        .login-frame {
            width: 86%;
            margin-left: -43%;
            font-size:100%;
        }
        .register-send-button {
            margin-bottom:40px;
        }
        .login-header {
            margin-top: 0px;
        }
        .login-frame {
            padding: 30px 60px;
            top: 20%;
        }
            .login-frame .awsome_input {
                font-size: 1.5em;
            }
    }
</style>
