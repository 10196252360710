<template>
    <main>
        <h1 class="h1">{{user.fullname}}</h1>
        <div class="content-text content-text-partner">
            <div class="partner-menu">
                <PartnerMenu></PartnerMenu>
                <div class="partner-totals">
                    <div class="partner-totals-col">
                        Υπολοιπο
                        <div class="partner-totals-info">{{numberFormat(balance)}}</div>
                    </div>
                    <div class="partner-totals-col">
                        Πληρωμες
                        <div class="partner-totals-info">{{numberFormat(credit)}} &euro;</div>
                    </div>
                    <div class="partner-totals-col">
                        Συνολο χρεωσης
                        <div class="partner-totals-info">{{numberFormat(debit)}}</div>
                    </div>
                </div>
            </div>

            <div class="content-product">
                <div class="admin-rent-frame admin-panel">
                    <!--Comming soon...-->
                    <img class="loading-class" v-show="loading" src="../assets/loading.gif" />
                    <div v-html="html"></div>
                    <div v-if="html == '' && loading == false"><br/><br /><br />Δεν υπάρχουν απλήρωτα προϊόντα</div>
                    <br/><br /><br />
                    <!--<div v-if="data.length == 0 && loading == false"><br/><br /><br />Δεν υπάρχουν απλήρωτα προϊόντα</div>-->
                    <!--<div v-if="data.length != 0" class="admin-panel">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="header-td">ΗΜΕΡΟΜΗΝΙΑ</td>
                                    <td class="header-td">ΤΙΜΟΛΟΓΙΟ</td>
                                    <td class="header-td">ΚΩΔΙΚΟΣ</td>
                                    <td class="header-td">ΠΕΡΙΓΡΑΦΗ</td>
                                    <td class="header-td">ΧΡΕΩΣΗ</td>
                                </tr>
                                <tr v-for="item of data" v-bind:key="item" :class="getClass(item)">
                                    <td>{{item.dateString}}</td>
                                    <td>{{item.invoiceNo}}</td>
                                    <td>{{item.productCode}}</td>
                                    <td>{{item.productTitle}}</td>
                                    <td>{{numberFormat(item.total)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>-->
                    <!--<div class="product-item" v-for="item of data" v-bind:key="item">
                        <div class="product-description" alt="{{ item.supplier }}">
                            <b>{{ item.date }}</b>  - {{ item.invoiceNo }} - {{ item.paid }} - {{ item.total }}
                        </div>
                    </div>-->
                </div>
            </div>

        </div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    import axios from 'axios'
    export default {
        name: 'PartnerRent',
        props: {
            msg: String
        },
        data() {
            return {
                data: [],
                products: [],
                balance: 0,
                debit: 0,
                credit: 0,
                html: '',
                loading: true,
                invoiceNo: ''
            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            getClass(item) {

                var curClass = "td-total";

                if (this.invoiceNo == item.invoiceNo || this.invoiceNo == '') {
                    curClass = "td-details";
                }

                this.invoiceNo = item.invoiceNo;

                return curClass;
            },
            getData() {
                axios.get('/api/partner/sales', {
                    params: {
                        //id: this.$route.params.id
                        id: this.user.id
                    }
                }).then((response) => {
                    this.products = response.data.items;
                    //this.qty = response.data.qty;
                    //this.qtyTotal = response.data.qtyTotal;
                    //this.total = response.data.total;
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            getHtmlData() {
                axios.get('/api/partner/statistics', {
                    params: {
                        type: 'getadmins',
                        id: this.user.id
                    }
                }).then((response) => {
                    console.log(response);
                    this.data = response.data.data;
                    this.html = response.data.html;
                    this.balance = response.data.balance;
                    this.credit = response.data.credit;
                    this.debit = response.data.debit;
                    this.loading = false;

                })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
            isLoggedIn: function () { return this.$store.getters.isLoggedIn },
            user: function () { return this.$store.getters.user }
        },
        mounted() {
            if (!this.isLoggedIn) this.$router.push('/');
            //this.getData();
            this.getHtmlData();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
    /*.partner-totals-info {
        font-weight: bold;
        margin: 10px 0 20px 0;
    }
    .partner-totals {
        text-align: left;
        margin: 60px auto 0;
        color: white;
        font-family: "Bebas Neue";
        font-size: 1.3em;
        letter-spacing: 2px;
        width:845px;
    }
    .partner-totals-col {
        display: inline-block;
        margin-right: 270px;
        text-transform:uppercase;
    }
    .partner-totals-col:last-child {
        margin-right: 0px;
    }
    .content-product {
        font-family: 'Ubuntu Condensed';
    }
    .partner-menu {
        background-color: #00828b;
        padding: 80px 0 40px 0;
    }
    .content-text-partner {
        width: 70%;
        margin-top: 2%;
    }
    .product-image {
        width: 100%;
        height: 0px;
        padding-bottom: 86.25%;
        background-color: gray;
        position: relative;
        cursor: pointer;*/
        /*background-size: auto 100%;*/
        /*background-size: cover;
        background-position: center center;
    }
    .product-frame {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        margin-top:40px;
    }

    .product-item {
        width: 23%;
        margin: 2% 1%;
        height: auto;
        position: relative;
    }

    .product-description {
        margin: 15px 0 0 0;
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        margin-bottom: 5px;
        position: relative;
        text-transform: uppercase;
        height: 46px;
        line-height: 20px;
        overflow:hidden;
    }*/

    /* admin */
    .loading-class {
        margin-top:40px;
    }
    .admin-supplier {
        font-weight: bold;
        font-size: 20px;
        margin: 0px 0px 10px 0px
    }

    .admin-total-teims span {
        font-weight: bold;
    }

    .admin-supplier-items {
        margin: 0px 0px 0px 0px;
    }

    .admin-supplier-item {
        float: left;
        font-size: 12px;
        display: inline-block;
        height: 200px;
        width: 200px;
        text-align: center;
        margin: 10px 10px 0px 0px;
        font-weight: normal;
    }

        .admin-supplier-item img {
            max-height: 100px;
        }

    .admin-panel .balance {
        color: #D9041D;
    }

    .admin-panel {
        margin-top:30px;
    }

    .admin-panel table {
        width: 100%;
    }

    .admin-panel .header-td {
        font-weight: bold;
        padding-bottom: 10px;
    }

    .admin-panel table {
        border: solid 1px #000000;
        border-collapse: collapse;
    }
        .admin-panel table tr.td-total td {
            border-bottom: solid 1px #000000;
            border-collapse: collapse;
            padding-bottom:20px;
        }

        .admin-panel table tr.td-details td {
            padding-top: 20px;
            padding-bottom:5px;
        }

        .admin-panel table  td:nth-child(5) {
            text-align:right;
        }
        .admin-panel table td:nth-child(4) {
            text-align: left;
            padding-left:30px;
        }

        .admin-panel table td {
            padding: 2px 6px 2px 6px;
            border-right: solid 1px #000000;
        }

    .admin-balance-total {
        text-align: right;
        margin-top: 20px;
    }

    .logo img {
        max-height: 140px;
    }
    /* end of admin */
</style>
