<template>
    <main>
        <div class="content-product">
            <div class="contact-bottom-text categories-width-fixed">
                <div class="contact-text-1">MORE PROPS EVERYDAY</div>
                <div class="contact-text-2">DON'T MISS THEM OUT!</div>
            </div>
        </div>
    </main>
    <transition name="slide-fade">
        <!--<div class="menu-left-fixed white-style green-bg-text" @click="show=!show" id="menu-left-fixed" v-if="show">-->
        <div class="menu-left-fixed white-style " :class="getCurrentClass()" id="menu-left-fixed">
            <!--<ul  @click="toggle =! toggle">-->
            <ul>
                <li v-for="(category) of categories" v-bind:key="category">
                    <!--<a v-show="$route.query.type==null" @click="openChildMenu(key)">{{ fixGreek(category.title) }}</a>
                    <a v-show="$route.query.type!=null" @click="changeCategory(category.id)">{{ fixGreek(category.title) }}</a>-->

                    <a v-if="!category.hasChild" :class="[category.websiteCss]" @click="changeCategory(category.id)">{{ fixGreek(category.title) }}</a>
                    <a v-if="category.hasChild" :class="[category.websiteCss]" @click="openChildMenu(category.brandId)">{{ fixGreek(category.title) }}</a>

                    <!--<router-link v-if="!isParent()" @click="openChildMenu()"
                    :to="{ name: 'Product', params: { id: category.id } }">{{ fixGreek(category.title) }}</router-link>-->
                <transition name="slideme">
                    <ul class="menu-child-left" v-if="selected==category.brandId">
                        <li v-for="(child, keyChild) of category.children" v-bind:key="child">
                            <a v-if="!child.hasChild" :class="[child.websiteCss]" @click="changeCategory(child.id)">{{ child.title }}</a>
                            <a v-if="child.hasChild" :class="[child.websiteCss]" @click="openChildMenu2(keyChild, child.id)">{{ fixGreek(child.title) }} ></a>
                            <transition name="slideme">
                                <ul class="menu-child-left" v-if="selectedChild==keyChild">
                                    <li v-for="(child2, keyChild2) of categoriesChild" v-bind:key="child2">
                                        <a v-if="!child2.hasChild" @click="changeCategory(child2.id)">{{ child2.title }}</a>
                                        <a v-if="child2.hasChild" @click="openChildMenu2(keyChild2, child2.id, 2)">{{ fixGreek(child2.title) }} ></a>
                                        <transition name="slideme">
                                            <ul class="menu-child-left" v-if="selectedChild2==keyChild2">
                                                <li v-for="(child3, keyChild3) of categoriesChild2" v-bind:key="child3">
                                                    <a v-if="!child3.hasChild" @click="changeCategory(child3.id)">{{ child3.title }}</a>
                                                    <a v-if="child3.hasChild" @click="openChildMenu2(keyChild3, child3.id, 3)">{{ fixGreek(child3.title) }} ></a>
                                                    <transition name="slideme">
                                                        <ul class="menu-child-left" v-if="selectedChild3==keyChild3">
                                                            <li v-for="(child4, keyChild4) of categoriesChild3" v-bind:key="child4">
                                                                <a v-if="!child4.hasChild" @click="changeCategory(child4.id)">{{ child4.title }}</a>

                                                                <a v-if="child4.hasChild" @click="openChildMenu2(keyChild4, child4.id, 4)">{{ fixGreek(child4.title) }} ></a>
                                                                <transition name="slideme">
                                                                    <ul class="menu-child-left" v-if="selectedChild4==keyChild4">
                                                                        <li v-for="(child5) of categoriesChild4" v-bind:key="child5">
                                                                            <a @click="changeCategory(child5.id)">{{ child5.title }}</a>

                                                                            <a v-if="child5.hasChild" @click="openChildMenu2(keyChild5, child5.id, 5)">{{ fixGreek(child5.title) }} ></a>
                                                                            <transition name="slideme">
                                                                                <ul class="menu-child-left" v-if="selectedChild5==keyChild5">
                                                                                    <li v-for="(child6) of categoriesChild5" v-bind:key="child6">
                                                                                        <a @click="changeCategory(child5.id)">{{ child6.title }}</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </transition>
                                                                        </li>
                                                                    </ul>
                                                                </transition>
                                                            </li>
                                                        </ul>
                                                    </transition>

                                                </li>
                                            </ul>
                                        </transition>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                    </ul>
                </transition>
                </li>
            </ul>
        </div>
    </transition>
    <!--<social socialClass="social-right fixed"></social>-->
    <div class="categories-bottom-text categories-width-fixed">
        <div class="row contact-social-frame">
            <div class="col-sm-11">
                <div class="contact-text-3">
                    <p>PALLADOS ATHINAS 10 GLYKA NERA</p>
                    <p>ATHENS, GREECE 15354</p>
                    <p>+30 213 0332316</p>
                </div>
            </div>
            <div class="col-sm-1 white-style">
                <social></social>
            </div>
        </div>
    </div>
    <transition name="fade-modal">
        <alert :messages="errorsLocal" :info="true"></alert>
    </transition>
</template>


<script>
    import axios from 'axios'
    import jQuery from 'jquery'
    import { mapActions } from 'vuex'
    //window.jQuery = jQuery

    export default {
        name: 'Product',
        props: {
            msg: String
        },
        beforeCreate: function () {
            document.body.className = ' white-style green-bg';
        },
        data() {
            return {
                show: false,
                toggle: false,
                showModal: false,
                productId: '',
                category: '',
                parent: '',
                parent1: '',
                parent2: '',
                parent3: '',
                parentId: '',
                parent1Id: '',
                parent2Id: '',
                parent3Id: '',
                parent4Id: '',
                parent5Id: '',
                parent6Id: '',
                parent7Id: '',
                parent8Id: '',
                parent9Id: '',
                parent10Id: '',
                selected: undefined,
                textMoreProps: 'More props',
                products: [],
                categories: [],
                selectedChild: undefined,
                selectedChild2: undefined,
                selectedChild3: undefined,
                selectedChild4: undefined,
                selectedChild5: undefined,
                selectedChild6: undefined,
                selectedChild7: undefined,
                selectedChild8: undefined,
                selectedChild9: undefined,
                selectedChild10: undefined,
                categoriesChild: [],
                categoriesChild2: [],
                categoriesChild3: [],
                categoriesChild4: [],
                categoriesChild5: [],
                categoriesChild6: [],
                categoriesChild7: [],
                categoriesChild8: [],
                categoriesChild9: [],
                categoriesChild10: [],
                errorsLocal: [],

                currentPage: 1,
                perPage: 24,
                totalComponent: 16,
                totalPages: 0,
                hasMorePages: true
            }
        },
        methods: {
            ...mapActions(["addCartItem"]),
            changeCategory(id) {
                this.$router.push({ name: 'Product', params: { id: id }, query: { type: this.$route.query.type } })
                return this.show = false;
            },
            changeCategoryParent(id) {
                this.$router.push({ name: 'Categories', params: { id: id }, query: { type: this.$route.query.type } })
                return this.show = false;
            },
            closeModal() {
                //alert('test');
                //this.$route.params.product_id = undefined;
                this.$router.push({ name: 'Product', params: { id: this.$route.params.id }, query: { product_id: undefined, type: this.$route.query.type } })
                return this.showModal = false;
            },
            getCategories() {
                //set null variables

                if (this.$route.query.type == 'elements') {
                    this.categories = [{ title: "ΚΟΥΖΙΝΕΣ", id: 0 }, { title: "ΠΑΓΚΟΣ", id: 0 }, { title: "ΠΟΡΤΕΣ", id: 0 }, { title: "RECEPTION", id: 0 }, { title: "TAMEIO ΘΕΑΤΡΟΥ", id: 0 }];
                }
                else {
                    axios.get('/api/category/full', {
                        params: {
                            //id: this.$route.params.id
                            type: this.$route.query.type,
                            id: '0'
                        }
                    }).then((response) => {
                        //console.log(response.data);
                        this.categories = response.data;
                        if (response.data.length == 1) {
                            this.openChildMenu(0);
                        }
                        else {
                            this.openChildMenu(0);
                        }
                    })
                        .catch(function (error) {
                            alert(error);
                        });
                }
            },
            getCategoriesChild(id, type = 1) {
                axios.get('/api/category/', {
                    params: {
                        //id: this.$route.params.id
                        type: this.$route.query.type,
                        id: id
                    }
                }).then((response) => {
                    //console.log(response.data);

                    if (type == 1) {
                        this.categoriesChild = response.data;
                    }
                    else if (type == 2) {
                        this.categoriesChild2 = response.data;
                    }
                    else if (type == 3) {
                        this.categoriesChild3 = response.data;
                    }
                    else if (type == 4) {
                        this.categoriesChild4 = response.data;
                        //console.log(this.categoriesChild4);
                    }
                    else if (type == 5) {
                        this.categoriesChild5 = response.data;
                    }
                    else if (type == 6) {
                        this.categoriesChild6 = response.data;
                    }
                    else if (type == 7) {
                        this.categoriesChild7 = response.data;
                    }
                    else if (type == 8) {
                        this.categoriesChild8 = response.data;
                    }
                    else if (type == 9) {
                        this.categoriesChild9 = response.data;
                    }
                    else if (type == 10) {
                        this.categoriesChild10 = response.data;
                    }

                    if (response.data.length == 1) {
                        //this.openChildMenu(0);
                    }
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            getData(pageNumber) {

                if (this.$route.params.id == '') {
                    return;
                }

                axios.get('/api/products/fullPaging', {
                    params: {
                        id: this.$route.params.id,
                        page: pageNumber,
                        pageSize: this.perPage
                    }
                }).then((response) => {
                    //console.log(response.data.items);
                    this.products = response.data.items;
                    this.category = response.data.category;
                    this.parent = response.data.parent;
                    this.parent1 = response.data.parent1;
                    this.parent2 = response.data.parent2;
                    this.parent3 = response.data.parent3;
                    this.parent4 = response.data.parent4;
                    this.parent5 = response.data.parent5;
                    this.parent6 = response.data.parent6;
                    this.parent7 = response.data.parent7;
                    this.parent8 = response.data.parent8;
                    this.parent9 = response.data.parent9;
                    this.parent10 = response.data.parent10;

                    this.parentId = response.data.parentId;
                    this.parent1Id = response.data.parent1Id;
                    this.parent2Id = response.data.parent2Id;
                    this.parent3Id = response.data.parent3Id;
                    this.parent4Id = response.data.parent4Id;
                    this.parent5Id = response.data.parent5Id;
                    this.parent6Id = response.data.parent6Id;
                    this.parent7Id = response.data.parent7Id;
                    this.parent8Id = response.data.parent8Id;
                    this.parent9Id = response.data.parent9Id;
                    this.parent10Id = response.data.parent10Id;

                    this.totalComponent = response.data.totalComponent;
                    this.totalPages = response.data.totalPages;
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            isParent() {
                return this.$route.params.id == '' || this.$route.params.id == undefined;
            },
            openFrame() {
                this.menu.toggle('slide', { direction: 'left' }, 500);

            },
            showModalPopup(id) {
                this.productId = this.getImage(id);
                //this.showModal = true;
                this.$router.push({ name: 'Product', params: { id: this.$route.params.id }, query: { product_id: id, type: this.$route.query.type } })

            },
            addToBasket(id, code, rent) {
                //product.qtyRent==0 || product.isRent==1
                if (rent) {
                    this.errorsLocal = ['Ελλειψη στοκ'];
                    return;
                }

                var item = {
                    id: id,
                    qty: 1,
                    code: code,
                    orderId: this.getUID()
                };

                //this.addCartItem(item);

                this.$store.dispatch('addCartItem', item)
                    .then((resp) => {
                        //console.log(resp.data);
                        if (resp.data == 0) {
                            this.errorsLocal = ['Ελλειψη στοκ'];
                            //console.log("MIssing stock!");
                        }
                        else {
                            this.errorsLocal = ['Το προϊόν προστέθηκε με επιτυχία στο καλάθι αγορών σας!'];
                            //console.log("product added!");
                        }

                        setTimeout(this.closeAlert, 1000);

                    })
                    .catch(err => console.log(err))

            },
            closeAlert() {
                this.errorsLocal = [];
            },
            getImage2() {
                return require('@/assets/sample.jpg');
            },
            getImage(photo) {
                //return '//recall.gr/images/eshop/' + photo;
                //console.log('photo', photo);
                var s = String(photo);
                if (s.toString().indexOf('https://') !== -1) {
                    return photo;
                }
                else {

                    return  this.$imageDomainFull + photo;
                }
            },
            openChildMenu(key) {
                this.categoriesChild = [];
                this.categoriesChild2 = [];
                this.categoriesChild3 = [];
                this.categoriesChild4 = [];
                this.categoriesChild5 = [];
                this.categoriesChild6 = [];
                this.categoriesChild7 = [];
                this.categoriesChild8 = [];
                this.categoriesChild9 = [];
                this.categoriesChild10 = [];
                this.parent = '';
                this.parent1 = '';
                this.parent2 = '';
                this.parent3 = '';
                this.parent4 = '';
                this.parent5 = '';
                this.parent6 = '';
                this.parent7 = '';
                this.parent8 = '';
                this.parent9 = '';
                this.parent10 = '';

                //this.toggle = !this.toggle;
                if (this.selected == key) {
                    this.selected = undefined
                }
                else {
                    this.selected = key;
                }

            },
            openChildMenu2(key, id, type = 1) {

                if (type == 1) {
                    if (this.selectedChild == key) {
                        this.selectedChild = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild = key;
                    }
                }
                else if (type == 2) {
                    if (this.selectedChild2 == key) {
                        this.selectedChild2 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild2 = key;
                    }
                }
                else if (type == 3) {
                    if (this.selectedChild3 == key) {
                        this.selectedChild3 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild3 = key;
                    }
                }
                else if (type == 4) {
                    if (this.selectedChild4 == key) {
                        this.selectedChild4 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild4 = key;
                    }
                    console.log(this.selectedChild4 == key);
                }
                else if (type == 5) {
                    if (this.selectedChild5 == key) {
                        this.selectedChild5 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild5 = key;
                    }
                }
                else if (type == 6) {
                    if (this.selectedChild6 == key) {
                        this.selectedChild6 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild6 = key;
                    }
                }
                else if (type == 7) {
                    if (this.selectedChild7 == key) {
                        this.selectedChild7 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild7 = key;
                    }
                }
                else if (type == 8) {
                    if (this.selectedChild8 == key) {
                        this.selectedChild8 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild8 = key;
                    }
                }
                else if (type == 9) {
                    if (this.selectedChild9 == key) {
                        this.selectedChild9 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild9 = key;
                    }
                }
                else if (type == 10) {
                    if (this.selectedChild10 == key) {
                        this.selectedChild10 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild10 = key;
                    }
                }

                //this.$router.push({ name: 'Categories', params: { id: id } });

            },
            getCurrentClass() {
                if (this.$route.query.type == '1') {
                    this.textMoreProps = "More structural elements";
                    return '';
                }
                else {
                    this.textMoreProps = "More props";
                    return 'green-bg-text';
                }
            },
            pageChange(pageNumber) {
                this.currentPage = pageNumber;
                this.getData(pageNumber);
            }
        },
        watch: {
            //$route() {
            //    this.getData();
            //},
            //'$route.params.id': function () {
            //    if (this.$route.params.id != undefined) {
            //        this.currentPage = 1;
            //        this.getData();
            //    }
            //},
            //'$route.query.product_id': function () {
            //    //this.showModalPopup(id);

            //    if (this.$route.query.product_id != undefined) {
            //        this.showModal = true;
            //    }
            //},
            //'$route.query.type': function () {

            //    if (this.$route.query.type == '1') {
            //        //alert('test');
            //    }
            //}
        },
        mounted() {

            //this.getData();
            this.getCategories();
            //if (this.$route.query.product_id != undefined) {
            //    this.showModal = true;
            //}
            //this.getData(this.currentPage);

            jQuery(document).ready(function () {
                //alert('test');
            })


        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .contact-bottom-text:before {
        content: "";
        width: 1px;
        height: 600px;
        background-color: #aaab2e;
        position: absolute;
        z-index: 9;
        left: -120px;
        top: -30px;
    }
    .contact-text-3 {
        font-size:19px;
    }
    .contact-text-3 p{
        line-height:19px;
    }
    .split-line {
        background-color: #008089;
        width: 2px;
        height: 28px;
        display: inline-block;
    }

    .product-price-simple, .product-price-multi {
        display: inline-block;
        width: 100%;
    }

    .left-product-cart {
        display: inline-block;
        width: 50%;
        text-align: right;
        line-height: 20px;
        padding-right: 5px;
    }

    .right-product-cart {
        display: inline-block;
        width: 50%;
        text-align: left;
        padding-right: 5px;
    }

    .parent {
        font-weight: normal !important;
    }

    .breadcrumb-product a {
        cursor: pointer;
    }

    .content-product {
        font-family: Ubuntu;
        margin: 10% auto 0 auto;
        width: 100%;
        font-family: 'Ubuntu Condensed';
    }

    .product-frame {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    }

    .product-item {
        width: 23%;
        margin: 2% 1%;
        height: auto;
        position: relative;
        font-family: 'Bebas Neue';
        letter-spacing: 1px;
    }

    .product-description {
        margin: 15px 0 0 0;
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        margin-bottom: 5px;
        position: relative;
        text-transform: uppercase;
        min-height: 65px;
        line-height: 20px;
        overflow: visible;
    }

    .description-space {
        height: 65px;
        line-height: 20px;
        overflow: hidden;
    }

    .product-price {
        text-align: center;
    }

    .product-wish {
        float: right;
    }

    .product-add-to-basket {
        width: 100%;
        position: absolute;
        bottom: -30px;
        left: 0;
        text-transform: uppercase;
        background-color: #e1e1e1;
        text-align: center;
        font-size: 1em;
        transition: bottom 0.2s;
        padding: 5px 0;
        cursor: pointer;
    }

    .product-button-frame {
        overflow: hidden;
        position: relative;
        width: 100%;
        position: absolute;
        top: -45px;
        left: 0;
        height: 30px;
    }

    .product-item:hover .product-add-to-basket {
        bottom: 0;
    }

    .product-new-label {
        text-transform: uppercase;
        right: -4px;
        position: absolute;
        top: 20%;
        background-color: #E33642;
        padding: 3px 10px;
        font-size: 0.7em;
        color: white;
        letter-spacing: 2px;
    }

    .breadcrumb-product {
        text-transform: uppercase;
        margin: 1% 1% 0 1%;
    }

        .breadcrumb-product span {
            font-weight: bold;
        }

    .product-image {
        width: 100%;
        height: 0px;
        padding-bottom: 86.25%;
        background-color: gray;
        position: relative;
        cursor: pointer;
        /*background-size: auto 100%;*/
        background-size: cover;
        background-position: center center;
    }

    .menu-left-fixed a {
        cursor: pointer;
        font-family: 'Bebas Neue';
        letter-spacing: 1px;
    }

    .menu-left-fixed > ul > li > a {
        font-size: 22px;
        letter-spacing: 1.7px;
    }

    .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(-120px);
        opacity: 0;
    }

    .menu-child-left {
        margin: 20px 20px;
    }

    .is-open {
        opacity: 1;
        height: auto;
    }

    .slidedown-enter-active,
    .slidedown-leave-enter {
        opacity: 0;
        height: 0;
        transition: all .3s ease-out;
    }

    .slidedown-enter,
    .slidedown-leave-to {
        opacity: 1;
        height: auto;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: 0.5s;
    }

    .slideme-enter {
        opacity: 0;
        transform: scale3d(2, 0.5, 1) translate3d(400px, 0, 0);
    }

    .slideme-enter-to {
        transform: scale3d(1, 1, 1);
        transition: 0.5s opacity;
    }

    .slideme-enter-active,
    .slideme-leave-active {
        transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .slideme-leave {
        transform: scale3d(1, 1, 1);
        transition: 0.5s opacity;
    }

    .slideme-leave-to {
        opacity: 0;
        transform: scale3d(2, 0.5, 1) translate3d(-400px, 0, 0);
    }

    .rotate-enter {
        transform: perspective(500px) rotate3d(0, 1, 0, 90deg);
    }

    .rotate-enter-active,
    .rotate-leave-active {
        transition: 0.5s;
    }

    .rotate-leave-to {
        transform: perspective(500px) rotate3d(0, 1, 0, -90deg);
    }


    .menu-left-fixed {
        overflow-y: scroll;
        height: calc(100% - 150px);
        margin-top: 100px;
        margin-bottom: 50px;
        z-index: 9;
        /*min-width: 500px;*/
    }

    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffc500;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #c79b06;
        }

    /* Track */
    .green-bg-text::-webkit-scrollbar-track {
        background: #017f8a;
    }

    /* Handle */
    .green-bg-text::-webkit-scrollbar-thumb {
        background: #ffffff;
    }

        /* Handle on hover */
        .green-bg-text::-webkit-scrollbar-thumb:hover {
            background: #017f8a;
        }
    /*
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }*/
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }

    .menu-close-product {
        right: 10px !important;
        top: 10px !important;
    }

    .mobile-add-basket {
        display: none;
    }
    .contact-bottom-text {
        background-color: #017f89;
        color: #fff;
        position: absolute;
        right: 50px;
        padding:unset;
        margin:unset;
        width:unset;
    }

    .categories-bottom-text {
        background-color: #017f89;
        color: #fff;
        position: absolute;
        right: 50px;
        padding: unset;
        margin: unset;
        top:50%;
    }
    .categories-width-fixed {
        width: 1100px;
    }
    @media screen and (max-width: 1600px) {
        .contact-text-1 {
            font-size: 3em;
        }

        .contact-text-2 {
            font-size: 38px;
            margin-top: 15px;
        }

        .categories-width-fixed {
            width: 900px;
        }
    }
    @media screen and (max-width: 1400px) {
        .categories-width-fixed {
            width: 800px;
        }
    }
    @media screen and (max-width: 1200px) {
        .contact-text-1 {
            font-size: 3em;
        }
        .contact-text-2 {
            font-size: 38px;
            margin-top:15px;
        }
        .categories-width-fixed {
            width: 700px;
        }
    }

    @media screen and (max-width: 1060px) {
        .contact-text-1 {
            font-size: 33px;
        }

        .contact-text-2 {
            font-size: 28px;
            margin-top: 15px;
        }
        .categories-width-fixed {
            width: 450px;
        }
        .split-line {
            width: 100%;
            height: 2px;
            display: none;
        }

        .left-product-cart {
            display: block;
            width: 100%;
            text-align: center;
            line-height: 20px;
            padding-right: 5px;
            /*border-bottom: #008089 solid 2px;*/
        }

        .right-product-cart {
            display: inline-block;
            width: auto;
            text-align: center;
            padding-right: 5px;
            line-height: 20px;
        }
    }
    @media screen and (max-width: 680px) {
        .menu-left-fixed {
            width: 100%;
        }
    
    }

    @media screen and (max-width: 580px) {

        .mobile-add-basket {
            display: inline-block;
            margin-left: 6px;
        }

        .description-space {
            font-size: 13px;
        }

        .product-item {
            width: 46%;
            margin: 2% 2%;
            height: 317px;
        }

        .product-image {
            width: 100%;
            height: 108px;
        }

        product-description {
            font-size: 12px;
        }

        .menu-left-fixed-button {
            width: 30px;
            font-size: 13px;
        }
    }

    @media screen and (max-width: 400px) {
        .product-item {
            height: 274px;
        }
    }
</style>
