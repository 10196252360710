<template>
    <!--<h1>Counter</h1>

    <p>This is a simple example of an Vue component.</p>

    <p aria-live="polite">Current count: <strong>{{ currentCount }}</strong></p>

    <button class="btn btn-primary" @click="incrementCounter">Increment</button>-->
    <div class="social " :class=" socialClass">
        <ul>
            <li><a target="_blank" href="https://www.instagram.com/prop_n_roll_/"><!--<i class="fa fa-instagram" aria-hidden="true"></i>--><font-awesome-icon :icon="['fab', 'instagram']" /></a></li>
            <li><a target="_blank" href="https://www.facebook.com/PropNRoll/"><!--<i class="fa fa-facebook-square" aria-hidden="true"></i>--><font-awesome-icon :icon="['fab', 'facebook-square']" /></a></li>
            <li><a target="_blank" href="https://www.youtube.com/channel/UCUKdivHgqqyqLJnnx-kiySw"><!--<i class="fa fa-youtube-play" aria-hidden="true"></i>--><font-awesome-icon :icon="['fab', 'youtube']" /></a></li>
        </ul>
    </div>
</template>


<script>
    export default {
        name: "Social",
        props: ['socialClass'],
        data() {
            return {
                currentCount: 0
            }
        },
        methods: {
            incrementCounter() {
                this.currentCount++;
            }
        }
    }
</script>
<style>
    @media screen and (max-width: 580px) {
        .social-right {
            right: 20px;
        }
        .social {
            left: 20px;
        }
    }
</style>