<template>
    <main>
        <h1 class="h1"><router-link :to="{ name: 'Elements' }">STRUCTURAL ELEMENTS</router-link> / {{result.title}}</h1>
        <div class="h1 decor-item-subheader">
            <span v-for="(item, key) in allResult.list" v-bind:key="item">
                <router-link :to="{ name: 'ElementItem', params: { id: item.id }  }">{{formatNumberZero(key+1)}}</router-link>
                <span class="slash-number" v-if="key+1 != allResult.list.length"> / </span>
            </span>
        </div>

        <div class="content-decor-item" v-for="image of result.images" v-bind:key="image">
            <div class="decor-image-frame">
                <div class="decor-item-image-subframe">
                    <div class="decor-item-image">
                        <img @mouseover="mouseOver($event, image.srcClick)"
                             @mouseleave="mouseOver($event, image.src)"
                             :src="getImage(image.src)" />
                        <div class="decor-item-image-description element-item-image-description ">
                            {{image.id}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>


<script>
    export default {
        name: 'Element-Item',
        props: {
            msg: String
        },
        data() {
            return {
                result: [],
                allResult: [],
                hover: true
            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            getData() {

                this.allResult = {
                    list: [{
                        id: '1', title: "ΚΟΥΖΙΝΕΣ", images: [
                            { id: '1', src: "kouzines_Page_03.jpg", srcClick: "kouzines_Page_04.jpg" },
                            { id: '2', src: "kouzines_Page_01.jpg", srcClick: "kouzines_Page_02.jpg" },
                            { id: '3', src: "kouzines_Page_05.jpg", srcClick: "kouzines_Page_06.jpg" },
                            { id: '4', src: "kouzines_Page_07.jpg", srcClick: "kouzines_Page_08.jpg" },
                            { id: '5', src: "kouzines_Page_09.jpg", srcClick: "kouzines_Page_10.jpg" }]
                    },
                        {
                            id: '2', title: "ΔΙΑΦΟΡΑ", images: [
                                { id: '1', src: "tameio.jpg", srcClick: "" },
                                { id: '2', src: "kouzines_Page_11.jpg", srcClick: "kouzines_Page_12.jpg" },
                            ]
                        }
                    ]
                };
                for (const item of this.allResult.list) {
                    if (item.id === this.$route.params.id) {
                        this.result = item
                    }
                }
            },
            mouseOver(event, image) {
                if (image != undefined && image != '') {
                    event.target.src = this.getImage(image);
                }
            },
        },
        watch: {
            '$route.params.id': function () {
                this.getData();
            }
        },
        mounted() {
            this.getData();

        }

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .element-item-image-description {
        top:0px;
        left:10px;
    }
    .decor-item-subheader .router-link-active {
        color: #ffc500;
    }
    .decor-item-subheader a{
        color: gray;
    }
    .decor-item-image {
        width: 100%;
        /*height: 400px;*/
        background-size: cover;
        background-position: center center;
    }

    .content-decor {
        font-family: Ubuntu;
        margin: 1% auto 0 auto;
        width: 90%;
    }

    .content-decor-item {
        font-family: Ubuntu;
        margin: 5% auto 0 auto;
        width: 60%;
        text-align:center;
    }

    .decor-item-image img{
        width:800px;
    }

    .decor-item-image-description {
        font-size: 8em;
        color: #ffc500;
        position:absolute;
    }

    .decor-item-image-telaro {
        top:40px;
        left:-40px;
    }

    .decor-item-image-final {
        top: 40px;
        left: -40px;
    }

    .decor-item-image-subframe {
        position: relative;
    }
    @media screen and (max-width: 580px) {
        .decor-item-image img {
            width: 100%;
        }
    }
</style>
