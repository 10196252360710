<template>
    <main>
        <div class="left-side left-side-about">
            <div class="text-2">
                <div class="about-line-paragraph">
                    <div class="bg-color-green-bg text-5">WHAT</div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="text-line-fill"></div>
                    &nbsp;&nbsp;&nbsp;
                    <div class="bg-color-green-bg text-5">IS</div>
                </div>
                <div class="about-line-paragraph">
                    <div class="bg-color-green-bg text-5">ALL&nbsp;&nbsp;</div>
                </div>
                <div class="about-line-paragraph text-align-left">
                    <div class="bg-color-green-bg text-5">ABOUT</div>
                    <div class="text-line-fill"></div>
                </div>

            </div>
        </div>
        <div class="right-side right-side-about">
            <div class="text-about">
                <p><em>Μπορεί ο χρόνος προετοιμασίας ενός γυρίσματος να συμπιεστεί προς όφελος του κόστους και της αποτελεσματικότητας; </em></p>
                <p>Σε αυτό το σχεδόν φιλοσοφικό ερώτημα η απάντηση ήταν η Propsmarket. Όλα τα props που χρειάζεται ένα διαφημιστικό γύρισμα, μία κινηματογραφική ταινία, μία σειρά, μία φωτογράφιση ακόμα και ένα party, οργανωμένα σε 1000 τετραγωνικά.</p>
                <p>4 χρόνια μετά, 40000 και πλέον καταχωρημένοι κωδικοί που εμπλουτίζονται καθημερινά, είναι διαθέσιμοι μέσω του site μας αλλά και προσβάσιμοι 24 ώρες, 365 ημέρες.</p>
                <p>Σήμερα η Propsmarket επεκτείνεται και δίνει τη θέση της στην Prop N Roll. Ό,τι χρειάζεται ένα γύρισμα όχι μόνο για την προετοιμασία του αλλά και για την εκτέλεσή του, βρίσκεται πλέον στον ίδιο χώρο, με διαφορά ενός ορόφου.</p>
                <p><strong>Props</strong><strong> και κινηματογραφικά </strong><strong>d</strong><strong>é</strong><strong>cor</strong> στο ίδιο κτίριο για ακόμα μεγαλύτερη ευελιξία, ταχύτητα και οικονομία χρόνου και κόστους.</p>
            </div>
        </div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    export default {
        name: 'About',
        props: {
            msg: String
        },
        beforeCreate: function () {
            document.body.className = 'white-style green-bg yellow-bg-line';
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .left-side-about {
        left: 20%;
    }

    .right-side-about {
        width: 30%;
        right: 20%;
        margin-top: 200px;
        z-index: 1;
    }
    @media screen and (max-width: 580px) {
        .right-side-about {
            width: 80%;
        }
        .text-2 {
            font-size: 1.4em;
            padding:5% 10%;
        }
        .text-5 {
            padding-left: -1px;
            letter-spacing:12px;
        }
        .left-side-about {
            left: 13%;
        }
        .left-side, .right-side {
            position: relative;
            top: 30%;
            color: #fff;
            display: block;
            right:unset;
            left:unset;
        }
        .right-side {
            top: 10%;
        }
        .left-side-about {
            left: unset;

        }

        .right-side-about {
            margin-top: 20px;
        }
    }
</style>

<style>
    @media screen and (max-width: 580px) {
        body.yellow-bg-line {
            background-size: 21px !important;
        }
    }
</style>
