<template>
    <div class="text-center">
        <a @click="scrollTop" v-show="visible" class="bottom-right">
            <img src="../assets/arrow-top.svg" class="arrow-top" />
        </a>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                visible: false
            }
        },
        methods: {
            scrollTop: function () {
                this.intervalId = setInterval(() => {
                    if (window.pageYOffset === 0) {
                        clearInterval(this.intervalId)
                    }
                    window.scroll(0, window.pageYOffset - 50)
                }, 20)
            },
            scrollListener: function () {
                this.visible = window.scrollY > 150
            }
        },
        mounted: function () {
            window.addEventListener('scroll', this.scrollListener)
        },
        beforeUnmount: function () {
            window.removeEventListener('scroll', this.scrollListener)
        }
    }
</script>
<style scoped>
    .arrow-top {
        height: 40px;
    }
    .bottom-right {
        position: fixed;
        bottom: 20px;
        right: 20px;
        cursor: pointer;
    }
    @media screen and (max-width: 580px) {

    }
</style>