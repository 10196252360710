
<template>
    <transition name="fade-modal">
        <div class="newsletter" v-if="showSubcribe">
            <div id="mdiv" @click="closeForm()">
                <div class="mdiv">
                    <div class="md"></div>
                </div>
            </div>
            <div class="text-9">More props everyday</div>
            <div class="green-color text-8">DON'T MISS THEM OUT!</div>
            <div class="text-9"><a class="button-sbuscribe" @click="subscribe" >subscribe</a></div>
        </div>
    </transition>
</template>


    <script>
        export default {
            name: "Subscribe",
            data() {
                return {
                    showSubcribe: true
                }
            },
            //props: ['socialClass'],
            methods: {
                closeForm() {
                    this.showSubcribe = !this.showSubcribe;
                },
                subscribe(){
                    this.showSubcribe = !this.showSubcribe;
                },
            }
        }
    </script>
    <style>
        .button-sbuscribe {
            text-decoration:underline;
            cursor:pointer;
        }

        @media screen and (max-width: 580px) {
        }
    </style>
