<template>
    <div class="login-frame">
        <div id="mdiv" class="black-close" @click="onCloseButton">
            <div class="mdiv">
                <div class="md"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <p>REGISTERED PARTNER</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <input type="text" class="awsome_input" v-on:keyup.enter="login" v-model="email" placeholder="E-MAIL*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <input type="password" v-on:keyup.enter="login" class="awsome_input" v-model="password" placeholder="PASSWORD*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <a href="#">
                            <span @click="login" class="register-send-button">LOG IN</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <p>NEW PARTNER</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <a href="#" @click="onRegisterClick">
                            <span class="register-send-button button-new-account">CREATE NEW ACCOUNT</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert :messages = "errorsLocal"></alert>
</template>


<script>
    import axios from 'axios'
    export default {
        name: 'Login',
        props: {
            msg: String
        },
        data() {
            return {
                errorsLocal: []
            }
        },
        methods: {
            onCloseButton() {
                this.$emit('close-clicked')
            },
            onRegisterClick() {
                this.$emit('register-clicked')
            },
            login: function () {
                if (this.checkFields()) {
                    let email = this.email
                    let password = this.password
                    this.$store.dispatch('login', { email, password })
                        //.then(() => this.onCloseButton())
                        //.then(() => this.$router.push('/'))
                        .then((resp) => {
                            if (resp.data.id == 0) {
                                this.errorsLocal = ["Wrong email or password!"]
                            }
                            else {
                                this.onCloseButton();
                                this.$router.push({ name: 'Partner' });
                            }
                        })
                        .catch(err => console.log(err))
                }
            },
            checkFields() {
                if (!this.password || !this.email) {
                    this.errorsLocal = ["All fields are required!"];
                    return false;
                }
                //else if (!this.validEmail(this.email)) {
                //    this.errorsLocal = ["Check your email"];
                //}
                else {
                    return true;
                }

            },
            login1() {
                this.$errors.push('Name required.');
                this.$errors = ['e', 'r', 'd'];
                //this.$refs.Error.test();
                //console.log(this.errors);
                //debugger;
                axios.post('/api/account/login', {
                    password: this.password,
                    username: this.email
                }).then((response) => {
                    console.log(response);
                    if (response.data != "") {
                        localStorage.setItem('user', JSON.stringify(response.data));
                        this.onCloseButton();
                    }
                    else {
                        console.log("Login failed!");
                        this.errorsLocal = ["Login failed!"];
                    }
                    //console.log(response.data.id);
                    
                    //var test = JSON.parse(localStorage.getItem('user'));
                    //console.log(test.id);
                    //console.log(localStorage.getItem('user'));
                    
                })
                    //.catch(function (error) {
                        //alert(error);
                    //});
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .login-frame {
        background-color: white;
        padding: 60px;
        position: fixed;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -400px;
        width: 800px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .login-frame p, .login-frame h1{
        color:black;
    }
        .login-frame .awsome_input {
            font-size: 2em;
        }
        .login-frame .contact-email {
            font-family: Roboto Condensed;
            font-weight:bold;
        }
    .register-send-button {
        margin-top: 40px;
        border: 1px solid black;
        display: inline-block;
        padding: 15px 40px 10px;
        color: black;
        font-size: 2em;
    }
    .login-header {
        font-size: 2em;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .button-new-account {
        margin-top: 0px;
    }
    @media screen and (max-width: 580px) {
        .login-frame {
            width: 86%;
            margin-left: -43%;
            font-size:100%;
        }
        .register-send-button {
            margin-bottom:40px;
        }
        .login-header {
            margin-top: 0px;
        }
        .login-frame {
            padding: 30px 60px;
            top: 20%;
        }
            .login-frame .awsome_input {
                font-size: 1.5em;
            }
    }
</style>
