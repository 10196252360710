<template>
    <main>
        <div class="content-prop">
            <div class="text-align-left">
                <div class="prop-text-number">
                    {{ animatedNumber }}<!--40.231-->
                </div>
                <div class="button-rent-now-align">
                    <span class="button-rent-now" @click="navigate">Rent now</span>
                </div>
            </div>
            <div class="text-align-right">
                <div class="prop-header-slogan">
                    <div class="text-2">
                        <div class="about-line-paragraph text-align-right">
                            <div class="bg-color-green-bg text-header-prop">KEEP<br />THEM<br />COMING</div>
                            <!--<div class="text-line-fill"></div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-align-left">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-about text-about-header">
                            <p>
                                Είτε είστε ιδιώτες, είτε εταιρεία παραγωγής αναλαμβάνουμε για εσάς τη φύλαξη,
                                <br />τη συντήρηση και φυσικά την ασφάλιση τους.
                                <br /><strong>Γιατί τα </strong><strong>props</strong><strong> μας είναι και δικά σας.</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row text-about-paragraph" >
                    <div class="col-sm-6">
                        <div class="text-about">
                            <div class="header-about">Ιδιωτες</div> <p>Η κουνιστή πολυθρόνα της γιαγιάς, το υπερ-μοντέρνο φορητό πλυντήριο πιάτων που τελικά αποδείχθηκε μικρό και τώρα σου πιάνει χώρο, το εντυπωσιακό κράνος που σου χάρισαν αλλά ξέχασαν πως δεν οδηγείς μηχανή, καθώς και οτιδήποτε άλλο θεωρείς πως μπορεί να βρεθεί στα ράφια της prop n roll είναι ευπρόσδεκτο.</p>
                            <p>Εδώ, όχι μόνο αποθηκεύεις δωρεάν τα props σου αλλά βγαίνεις και κερδισμένος με κάθε ενοικίαση τους.</p>
                        </div>
                    </div>
                    <div class="col-sm-6 ">
                        <div class="text-about">
                            <div class="header-about">Εταιρειες Παραγωγης</div> <p>Ήταν κάποτε μια κούκλα. Είχε μάλιστα παίξει και σε διαφημιστικό. Τώρα πια δεν είναι. Την βρήκαν με το μάγουλο συμπιεσμένο και σκισμένο να υποφέρει κάτω από το πόδι μίας καρέκλας καφενείου. Πάνω στην καρέκλα μια βαριά κούτα με πεταμένα μετάλλια πίεζε ακόμα περισσότερο το σκισμένο μάγουλο, ενώ μια μπάλα του γκολφ και ένα μίξερ ολοκλήρωναν το κερασάκι στην τούρτα της ακαταστασίας. Αραχνιασμένα props, στοιβαγμένα το ένα πάνω στο άλλο και ένα prop που κάποτε ήταν κούκλα, αλλά τώρα είναι για πέταμα.</p>
                            <p>Στην prop n roll μπορείτε να είστε ήσυχοι ότι τα props σας θα παραμείνουν όπως ήρθαν. Σε ένα χώρο απόλυτα οργανωμένο που μοιάζει περισσότερο με έκθεση παρά με αποθήκη. Εδώ, σας παρέχουμε <strong>δωρεάν</strong> τον χώρο που χρειάζεστε για να αποθηκεύετε τα props σας, ενώ ταυτόχρονα αναλαμβάνουμε τη συντήρηση και την ασφάλιση τους. Παράλληλα σας παρέχουμε ολοκληρωμένες υπηρεσίες ηλεκτρονικής αποθήκης, ώστε να έχετε πλήρη εικόνα για το σύνολο τον αντικειμένων σας. Έτσι, μπορείτε να τα εκμεταλλεύεστε αποτελεσματικά σε επόμενες παραγωγές και να μειώνεται ακόμα περισσότερο το κόστος που έχει να κάνει με τα props. Ήρθη η ώρα να έχετε οργανωμένη αποθήκη, χωρίς να πληρώνετε αποθήκη και μάλιστα ανοιχτή 24 ώρες, 365 μέρες το χρόνο.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-show="false">
                <subscribe></subscribe>
            </div>
            <top-button></top-button>
        </div>
    </main>
    <social socialClass=" fixed"></social>
</template>


<script>
    import gsap from "gsap";
    export default {
        name: 'Prop',
        props: {
            msg: String
        },
        data() {
            return {
                number: 35000,
                tweenedNumber: 0
            }
        },
        beforeCreate: function () {
            document.body.className = 'white-style green-bg';
        },
        computed: {
            animatedNumber: function () {
                return this.tweenedNumber.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                //return this.tweenedNumber.toFixed(0);
            }
        },
        methods: {
            runCalulation() {
                gsap.to(this.$data, { duration: 0.5, tweenedNumber: 40231 });
            },
            navigate() {
                this.$router.push({ name: 'Categories'})
            }
        },
        //watch: {
        //    number: function (newValue) {
        //        gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
        //    }
        //},
        mounted() {
            this.runCalulation();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .button-rent-now {
        text-transform: uppercase;
        font-size: 2em;
        padding: 15px 60px;
        border: 1px solid white;
        cursor: pointer;
        letter-spacing: 3px;
        /*transition: background-color 0.2s;*/
        transition: all .5s ease-out;
        background: linear-gradient(to right, #ffc107 50%, #017f8a 50%);
        background-size: 200% 100%;
        background-position: right bottom;
    }

        .button-rent-now a:hover {
            text-decoration: none;
        }

        .button-rent-now:hover {
            /*background-color: #ffc107;*/
            background-position: left bottom;
        }
    .button-rent-now-align {
        text-align: left;
    }
    .button-rent-now {
        margin-bottom:40px;
        display:inline-block;
    }
    .header-about {
        text-transform: uppercase;
        font-family: 'Bebas Neue'!important;
        letter-spacing: 8px;
        font-size: 2.8em;
        margin: 50px 0 50px 0;
        line-height: 1.2em;
        color: #00b1bc;
    }
    .text-about-header {
        font-family: 'Bebas Neue';
        letter-spacing: 3px;
        font-size: 2em;
        margin-top:60px;
        font-weight:100;
    }
    .text-about-header p{
        line-height: 1.4em;
    }
    .text-about-paragraph p {
        font-family: Ubuntu;
        font-size:0.9em;
    }
    .text-about-header strong {
        font-weight: bold;
    }
    .text-about {
        padding-left:0px;
    }
    .text-header-prop {
        position: absolute;
        z-index: 3;
        display: inline-block;
        padding-left: 10px;
        font-size: 2.3em;
        letter-spacing: 20px;
        line-height: 1em;
        text-align: right;
        right: 0;
        top: -356px;
    }

        /*.text-header-prop:before,*/ .text-header-prop:after {
            content: " ";
            position: absolute;
            width: 150px;
            height: 6px;
            position: absolute;
            background-color: white;
        }

        .text-header-prop:before {
            top: 39px;
            left: -162px;
        }

        .text-header-prop:after {
            bottom: 49px;
            left: -162px;
        }
    .prop-header-slogan {
        position:relative;
    }

    .prop-text-number {
        font-family: 'Abril Fatface', cursive;
        font-size: 13em;
        margin-bottom: 50px;
        line-height: 1em;
        letter-spacing:4px;
    }
    @media screen and (max-width: 1400px) {
        .prop-text-number {
            font-size: 8em;
        }
        .text-header-prop {
            font-size: 1.8em;
            top: -290px;
        }
    }
    @media screen and (max-width: 1050px) {
        .text-header-prop {
            position: static;
        }
            .text-header-prop:before, .text-header-prop:after {
                width: 50px;
                display: none;
            }
    }
    @media screen and (max-width: 580px) {
        .text-header-prop {
            position: static;
        }

        .button-rent-now-align {
            text-align: center;
        }

        .prop-text-number {
            font-size: 5em;
        }

        .text-header-prop:before, .text-header-prop:after {
            width: 50px;
            display: none;
        }

        .text-header-prop {
            font-size: 1.5em;
            letter-spacing: 14px;
        }

        .newsletter {
            margin-top: 70px;
        }

            .newsletter .text-9 {
                line-height: 1.2em;
            }

            .newsletter .text-8 {
                font-size: 4.3em;
                margin: 30px 0;
            }

        .prop-text-number {
            margin-bottom: 24px;
        }
    }
</style>
