<template>
    <main>
        <h1 class="h1">
            <router-link :to="{ name: 'Roll' }">ROLL</router-link> / 
            <router-link :to="{ name: 'Decor' }">DECOR</router-link>
        </h1>
        <div class="content-decor">
            <!--<div class="decor-image-frame decor-image-frame-left">
                <div class="decor-image-subframe">
                    <router-link :to="{ name: 'DecorItem', params: { id: 1 } }">
                        <div class="decor-image">
                            <img :src="getProject1()" />
                        </div>
                    </router-link>
                    <div class="decor-image-description">
                        <router-link :to="{ name: 'DecorItem', params: { id: 1 }  }">ΑΧΥΡΩΝΑΣ</router-link>
                    </div>
                    <div class="decor-image-number">
                        01
                    </div>
                </div>
            </div>

            <div class="decor-image-frame decor-image-frame-right">
                <div class="decor-image-subframe ">
                    <router-link :to="{ name: 'DecorItem' , params: { id: 2 }}">
                        <div class="decor-image">
                            <img :src="getProject2()" />
                        </div>
                    </router-link>
                    <div class="decor-image-description">
                        <router-link :to="{ name: 'DecorItem'  , params: { id: 2 }}">HOUSE</router-link>
                    </div>
                    <div class="decor-image-number">
                        02
                    </div>
                </div>
            </div>-->

            <div v-for="(item, key) of result.list" v-bind:key="item" class="decor-image-frame decor-image-frame-left">
                <div class="decor-image-subframe">
                    <router-link :to="{ name: 'DecorItem' , params: { id: item.id }}">
                        <div class="decor-image">
                            <img :src="getImage(item.images)" />
                        </div>
                    </router-link>
                    <div class="decor-image-description">
                        <router-link :to="{ name: 'DecorItem'  , params: { id: item.id }}">{{ item.title }}</router-link>
                    </div>
                    <div class="decor-image-number">
                        0{{key+1}}
                    </div>
                </div>
            </div>

        </div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    export default {
        name: 'Decor',
        props: {
            msg: String
        },
        data() {
            return {
                result: []
            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            getData() {

                this.result = {
                    list: [
                            { id: '1', title: "ΑΧΥΡΩΝΑΣ", images: "axironas.jpg" },
                            { id: '2', title: "HOUSE", images: "project2-1.jpg" },
                        { id: '3', title: "HOUSE", images: "project2.jpg" }
                        ]
                };
            },
            getProject1() {
                return require('@/assets/decor/axironas.jpg');
            },
            getProject2() {
                return require('@/assets/decor/project2.jpg');
            },
        },
        mounted() {
            this.getData();

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .decor-image-number {
        position: absolute;
        top: 30%;
        left: -47px;
        font-size: 4em;
        color: #ffc500;
        font-family: BioRhyme;
        font-weight: 900;
        letter-spacing: 4px;
    }
    .decor-image {
        width: 100%;
        /*height: 250px;*/
        background-size: cover;
        background-position: center center;
    }
    .decor-image img{
        width: 600px;
        background-size: cover;
        background-position: center center;
    }
    .decor-image-frame-right {
        text-align:right;
    }
    .decor-image-frame-left {
        text-align:left;
    }

    div.decor-image-frame:nth-child(odd) {
        text-align: left;
    }

    div.decor-image-frame:nth-child(even) {
        text-align: right;
    }

    @media screen and (max-width: 580px) {
        .decor-image-subframe {
            width:100%;
            position: relative;
        }
        .content-decor {
            width: 70%;
        }
        .decor-image {
            /*height: 250px;*/
        }
        .decor-image-number {
            left: -40px;
        }
        .decor-image img {
            width: 100%;
        }
    }
</style>
