//https://www.digitalocean.com/community/tutorials/how-to-build-a-shopping-cart-with-vue-3-and-vuex

import { createStore } from 'vuex'
import product from './modules/product';
import cart from './modules/cart';
import account from './modules/account';

export const store = createStore({
    modules: {
        product,
        cart,
        account
    }
});
