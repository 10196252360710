import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import About from "@/components/About.vue";
import Contact from "@/components/Contact.vue";
import Prop from "@/components/Prop.vue";
import Roll from "@/components/Roll.vue";
import Categories from "@/components/Categories.vue";
import Wrap from "@/components/Wrap.vue";
import Decor from "@/components/Decor.vue";
import Elements from "@/components/Elements.vue";
import DecorItem from "@/components/DecorItem.vue";
import ElementItem from "@/components/ElementItem.vue";
import Product from "@/components/Product.vue";
import Partner from "@/components/Partner.vue";
import PartnerRent from "@/components/PartnerRent.vue";
import Cart from "@/components/Cart.vue";
import NotAllow from "@/components/NotAllow.vue";
import { store } from '@/store'

//import Counter from "@/components/Counter.vue";
//import FetchData from "@/components/FetchData.vue";

var metaFacebook = {
    name: 'facebook-domain-verification',
    content: '1u1roqy0c1dwh7ctdr1csh0aedlezn'
};

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'Prop N Roll | Home Page',
            metaTags: [
                {
                    name: 'description',
                    content: '4 χρόνια μετά, 40000 και πλέον καταχωρημένοι κωδικοί που εμπλουτίζονται καθημερινά, είναι διαθέσιμοι μέσω του site μας αλλά και προσβάσιμοι 24 ώρες, 365 ημέρες'
                },
                {
                    property: 'og:description',
                    content: '4 χρόνια μετά, 40000 και πλέον καταχωρημένοι κωδικοί που εμπλουτίζονται καθημερινά, είναι διαθέσιμοι μέσω του site μας αλλά και προσβάσιμοι 24 ώρες, 365 ημέρες'
                },
                metaFacebook
            ]
        }
    },
    {
        path: "/about",
        name: "About",
        component: About,
        meta: {
            title: 'About | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: 'Contact | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/prop",
        name: "Prop",
        component: Prop,
        meta: {
            title: 'Prop | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/roll",
        name: "Roll",
        component: Roll,
        meta: {
            title: 'Roll | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/categories/:id?",
        name: "Categories",
        component: Categories,
        meta: {
            title: 'Categories | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/wrap",
        name: "Wrap",
        component: Wrap,
        meta: {
            title: 'Wrap | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/decor",
        name: "Decor",
        component: Decor,
        meta: {
            title: 'Decor | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/elements",
        name: "Elements",
        component: Elements,
        meta: {
            title: 'Elements | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/decor-item/:id?",
        name: "DecorItem",
        component: DecorItem,
        meta: {
            title: 'Decor | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/element-item/:id?",
        name: "ElementItem",
        component: ElementItem,
        meta: {
            title: 'Element | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/product/:id?",
        name: "Product",
        component: Product,
        meta: {
            title: 'Product | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    //{
    //    path: "/Counter",
    //    name: "Counter",
    //    component: Counter,
    //},
    //{
    //    path: "/FetchData",
    //    name: "FetchData",
    //    component: FetchData,
    //},
    {
        path: "/cart",
        name: "Cart",
        component: Cart,
        meta: {
            title: 'Cart | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        //path: "/partner",
        path: "/partner/:id?",
        name: "Partner",
        component: Partner,
        meta: {
            title: 'Partner | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/partner-rent",
        name: "PartnerRent",
        component: PartnerRent,
        meta: {
            title: 'Rent | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    },
    {
        path: "/not-allow",
        name: "NotAllow",
        component: NotAllow,
        meta: {
            title: 'Not Allow | Prop N Roll',
            metaTags: [
                metaFacebook
            ]
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router;