import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'es6-promise/auto'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
/*import VueMeta from 'vue-meta';*/
import Social from './components/Social.vue';
import ComFooter from './components/Footer.vue';
import Subscribe from './components/Subscribe.vue';
import TopButton from './components/TopButton.vue';
import Modal from './components/Modal.vue';
import Alert from './components/Alert.vue';
import Pagination from './components/Pagination.vue';
import PartnerMenu from './components/PartnerMenu.vue';
import { store } from './store';

//https://matteo-gabriele.gitbook.io/vue-gtag/v/next/
import VueGtag from "vue-gtag-next";

const app = createApp(App)

//app.prototype.$globalValue = 'Global Scope!';

app.config.globalProperties.$errors = []; //['d','b'];
app.config.globalProperties.$appName = 'Prop & Roll';
app.config.globalProperties.$basketCount = 0;
app.config.globalProperties.$imageDomain = 'cdn.propnroll.com';
app.config.globalProperties.$imageDomainFull = '//cdn.propnroll.com/images/shop/';


app.mixin({
    data: function () {
        return {
            storageKey: 'order-guid',
            get $asset() {
                return "Can't change me!";
            }
        }
    },
    methods: {
        fixGreek: function (text) {

            var s = text;

            if (text != null) {
                s = text.replace('ά', 'α').replace('ί', 'ι').replace('ή', 'η').replace('ύ', 'υ').replace('ό', 'ο').replace('ώ', 'ω').replace('έ', 'ε');
                //console.log(text);
                //s = s.replace();
                //console.log(s);
            }
            
            return s;
        },
        getPhoto(photo) {
            //return '//recall.gr/images/eshop/' + photo;

            var s = String(photo);

            if (s.indexOf('https://') !== -1) {
                return photo;
            }
            else {
                return '//' + this.$imageDomain +'/images/eshop/' + photo;
            }
        },
        getImage: function (image) {
            return '//' + this.$imageDomain +'/images/demo/' + image;
        },
        dateFormat: function (d) {

            var date = new Date(d);

            const result = date.toLocaleDateString("en-GB", { // you can use undefined as first argument
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            return result;
            //return parseInt(date.getDay()).toFixed(2) + "/" + parseInt(date.getMonth()).toFixed(2) + "/" + parseInt(date.getYear()).toFixed(2);
        },
        numberFormat: function (num) {
            if (!num) { return "0,00" }
            return parseFloat(num).toFixed(2).replace(".", ",")
        },
        formatNumberZero: function (number) {
            if (number < 10) {
                return '0' + number;
            }
            else {
                return number;
            }
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        getUID: function () {

            //var test = JSON.parse(localStorage.getItem('user'));
            //console.log(test.id);
            //console.log(localStorage.getItem('user'));
            var keyName = this.storageKey;

            if (localStorage.getItem(keyName) != null) {
                return localStorage.getItem(keyName);
            }
            else {
                var d = new Date();
                var dFull = d.getDate() + d.getMonth() + d.getFullYear();
                var dRand = Math.floor((Math.random() * 1000000) + 1);
                var sOrderGuid = dFull.toString() + dRand.toString();
                sOrderGuid = localStorage.setItem(keyName, sOrderGuid);
                return sOrderGuid;
            }
        },
        getStock(stock, rent) {
            if (rent) {
                return 0;
            }
            else {
                return stock;
            }
        },
        scrollTopPage: function () {
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                    clearInterval(this.intervalId)
                }
                window.scroll(0, window.pageYOffset - 50)
            }, 5)
        },
        removeUID: function () {
            localStorage.removeItem(this.storageKey);
        }
    },
})


app.component('font-awesome-icon', FontAwesomeIcon)
app.config.productionTip = false

app.component('Pagination', Pagination)
app.component('Modal', Modal)
app.component('Alert', Alert)
app.component('TopButton', TopButton)
app.component('Subscribe', Subscribe)
app.component('Social', Social)
app.component('ComFooter', ComFooter)
app.component('PartnerMenu', PartnerMenu)

app.use(store).use(router).use(VueGtag, {
    property: {
        id: "G-GT1DCVT9R7"
    },
    useDebugger: false
}).mount('#app')

//createApp(App).use(router).mount('#app')
