<template v-slot:header>
    <!--<transition name="modal" >-->
    <div class="modalform-mask">
        <div class="modalform-wrapper">
            <div class="modalform-container">
                <div class="modal-buttons modal-previous" v-show="previous!=0"><span @click="nextPreviousImage(previous)"><img src="../assets/previous-white.png"></span></div>
                <div class="modal-buttons modal-next" v-show="next!=0"><span @click="nextPreviousImage(next)"><img src="../assets/next1-white.png"></span></div>
                <div id="mdiv" class="black-close orange-close green-close" @click="$emit('close')">
                    <div class="mdiv">
                        <div class="md"></div>
                    </div>
                </div>
                <!--<div class="modalform-header" >
                    <slot name="header">
                        default header
                    </slot>
                </div>-->

                <div class="modalform-body">
                    <slot name="body">
                        <!--<img :src="getPhoto(products.photo)" />-->
                        <img :src="mainPhoto" />
                        <!--<div class="photoFrame" :style="{backgroundImage:`url('${mainPhoto}')`}"></div>-->
                    </slot>
                    <div class="thumbnails" v-show="images.length!=0">
                        <img :src="getPhoto(products.photo)" @click="changeImage(products.photo)" />
                        <span v-for="image of images" v-bind:key="image">
                            <img :src="getPhoto(image.source)" @click="changeImage(image.source)" />
                        </span>
                    </div>
                </div>

                <div class="modalform-footer">
                    <slot name="footer">
                        <div class="row">
                            <div class="col-sm-5 modal-block-div text-left">
                                <div class="modal-title">
                                    <b>{{ products.code }}</b> - {{ products.title }}
                                </div>
                            </div>
                            <div class="col-sm-2 modal-block-div text-center">
                                <div>
                                    <a :href="getPhoto(products.photo)" target="_blank">
                                        <img class="download" src="../assets/download-black.svg" />
                                    </a>
                                    <!--<img @click="download(getPhoto(products.photo))" class="download" src="../assets/download-black.svg" />-->
                                </div>
                            </div>
                            <div class="col-sm-5 modal-block-div text-right" v-show="products.priceApozimiosis==0 && $route.params.id != '0'">
                                <div>
                                    ΤΙΜΗ: <b>{{ numberFormat(products.price) }} €</b> &nbsp; ΤΕΜΑΧΙΑ: <b>{{products.qtyRent}}</b> &nbsp;
                                    <a class="shopping-cart-button"
                                       @click="addToBasket(products.id, products.code, products.qtyRent==0 || products.isRent==1)">
                                        <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-5 text-right" v-show="products.priceApozimiosis!=0 && $route.params.id != '0'">
                                <div class="left-product-cart">
                                    <span class="nowrap">ΕΝΟΙΚΙΑΣΗ <b>{{ numberFormat(products.price) }}€</b></span><br />
                                    <span class="nowrap">ΑΠΟΖΗΜΙΩΣΗ <b>{{ numberFormat(products.priceApozimiosis) }}€</b></span>
                                </div>
                                <div class="right-product-cart">
                                    <span class="split-line"></span>
                                    <span class="qty-multi">ΤΕΜΑΧΙΑ: <b>{{getStock(products.qtyRent, products.isRent)}}</b></span>
                                    <a class="shopping-cart-button"
                                       @click="addToBasket(products.id, products.code, products.qtyRent==0 || products.isRent==1)">
                                        <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-sm-5 text-right" v-show="$route.params.id == '0'">
                                <div class="product-price-simple">
                                    ΤΙΜΗ:<span class="old-price" v-show="products.discountOldPrice > 0">{{ numberFormat(products.discountOldPrice) }}€</span> <b>{{ numberFormat(products.salePrice) }} €</b> &nbsp; ΤΕΜΑΧΙΑ: <b>{{products.qty}}</b> &nbsp;
                                    <span class="mobile-add-basket">
                                        <a class="shopping-cart-button"
                                           @click="addToBasket(products.id, products.code, products.qtyRent==0 || products.isRent==1)">
                                            <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
    <!--</transition>-->
    <transition name="fade-modal">
        <alert :messages="errorsLocal" :info="true"></alert>
    </transition>
    <transition name="fade-modal">
        <movie v-if="showMovie" @register-clicked="openMovieForm()" @close-clicked="openMovieForm()" />
    </transition>
</template>


<script>
    import axios from 'axios';
    import { mapActions } from 'vuex'
    import movie from './MovieDetails.vue';

    export default {
        name: "Modal",
        props: ['img'],
        components: {
            movie
        },
        data() {
            return {
                showMovie: false,
                currentCount: 0,
                products: {},
                images: [],
                mainPhoto: '',
                next: 0,
                previous: 0,
                clickedButton: false,
                errorsLocal: []
            }
        },
        methods: {
            ...mapActions(["addCartItem"]),
            incrementCounter() {
                this.currentCount++;
            },
            openMovieForm() {
                this.showMovie = !this.showMovie;
            },
            download(photo) {
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                axios({
                    url: photo,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'file.jpg');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                });
            },
            changeImage(photo) {
                this.mainPhoto = this.getPhoto(photo);
            },
            nextPreviousImage(id) {
                //console.log(id);
                this.$router.push({ name: 'Product', params: { id: this.$route.params.id }, query: { product_id: id, type: this.$route.query.type } })
            },
            download1(photo) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(photo);
                a.href = url;
                a.download = 'image.jpg';
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            },
            //addToBasket(id, code) {

            //    var item = {
            //        id: id,
            //        qty: 1,
            //        code: code,
            //        orderId: this.getUID()
            //    };

            //    this.addCartItem(item);
            //},
            addToBasket(id, code, rent) {
                //product.qtyRent==0 || product.isRent==1


                if (this.buttonClicked) {
                    //this.errorsLocal = ['Wait'];
                    //return;
                }
                else {
                    this.buttonClicked = true;
                }

                if (this.$route.params.id == '0') {
                    this.errorsLocal = ['Για περισσότερες πληροφορίες συμπληρώστε τη φόρμα επικοινωνίας ή καλέστε μας στο 213-0332316'];
                    //setTimeout(this.closeAlert, 2000);
                    return;
                }

                if (rent) {
                    this.errorsLocal = ['Ελλειψη στοκ'];
                    return;
                }

                var item = {
                    id: id,
                    qty: 1,
                    code: code,
                    tempId: this.getUID()
                };

                //this.addCartItem(item);

                this.$store.dispatch('addCartItem', item)
                    .then((resp) => {

                        console.log(resp);

                        if (!resp.data.success) {
                            if (resp.data.errorCode == 100) {
                                this.openMovieForm();
                                return;
                            }
                            else {
                                console.log('test');
                                console.log(resp.data.errorMessage);
                                this.errorsLocal = [resp.data.errorMessage];
                                //this.isRentProduct = true;
                            }
                        }
                        else {
                            this.errorsLocal = ['Το προϊόν προστέθηκε με επιτυχία στο καλάθι αγορών σας!'];
                        }

                        this.buttonClicked = false;

                        //if (resp.data == 0) {
                        //    this.errorsLocal = ['Ελλειψη στοκ'];
                        //}
                        //else {
                        //    this.errorsLocal = ['Το προϊόν προστέθηκε με επιτυχία στο καλάθι αγορών σας!'];
                        //}

                        setTimeout(this.closeAlert, 1000);

                    })
                    .catch(err => console.log(err))

            },
            closeAlert() {
                this.errorsLocal = [];
            },
            getData() {
                //console.log(window.screen.height);
                //console.log(window.screen.width);
                axios.get('/api/product/fullimages', {
                    params: {
                        id: this.$route.query.product_id,
                        categoryId: this.$route.params.id
                    }
                }).then((response) => {
                    //this.products = response.data[0];
                    this.products = response.data.products[0];
                    this.images = response.data.images;
                    this.next = response.data.next;
                    this.previous = response.data.previous;
                    this.mainPhoto = this.getPhoto(this.products.photo);
                    //console.log('Images: ',this.images[1]);
                    //console.log(response.data);
                    //alert(response.data[0].photo);
                    //alert(this.products.photo);
                })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        watch: {
            '$route.query.product_id': function () {
                this.getData();
            },
        },
        mounted() {
            this.getData();
        }
    }
</script>
<style scoped>
    .nowrap {
        white-space:nowrap;
    }
    .qty-multi {
        display: inline-block;
        margin-right: 5px;
    }
    .right-product-cart {
        width: 107px;
    }
    .split-line {
        background-color: #008089;
        width: 2px;
        height: 33px;
        display: inline-block;
        margin-right:6px;
        vertical-align:middle;
    }
    .photoFrame {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
    }
    .thumbnails {
        width: 85px;
        position: absolute;
        right: -98px;
        bottom: 68px;
    }
    .thumbnails img{
        margin-bottom:10px;
        cursor:pointer;
    }
    .shopping-cart-button {
        cursor: pointer;
    }
    .download {
        width: 30px;
        cursor: pointer;
    }
    .modal-title {
        text-transform: uppercase;
    }
    .text-right, .text-left {
        display: table;
        font-family: "Ubuntu Condensed";
    }
        .text-right div, .text-left div {
            display: table-cell;
            vertical-align: middle;
            height: 40px;
        }
    .modalform-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modalform-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modalform-container {
        position:relative;
        width: 900px;
        /*max-height:700px;*/
        margin: 0px auto;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

        .modalform-container #mdiv {
            z-index: 9999;
        }

    .modalform-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modalform-body {
        margin: 0px 0;
        padding: 0;
        font-family: 'Bebas Neue';
        letter-spacing: 1px;
    }
    .modalform-body img{
        width:100%;
    }
    .modalform-default-button {
        float: right;
    }
    .modalform-footer {
        border-top: 0px;
        padding:15px 20px;
        line-height:20px;
    }
    .modal-buttons {
        position: absolute;
        top: 45%;
    }
    .modal-buttons img{
        height:50px;
        cursor:pointer;
    }
    .modal-next {
        right:-50px;
    }
    .modal-previous {
        left: -50px;
    }
    /*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

    .modalform-enter {
        opacity: 0;
        transition: opacity 0.7s ease;
    }

    .modalform-leave-active {
        opacity: 0;
        transition: opacity 0.7s ease;
    }

        .modalform-enter .modalform-container,
        .modalform-leave-active .modalform-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

    @media screen and (max-width: 1100px) {
        .modalform-container {
            width: 80% ;
        }
    }
    @media screen and (max-width: 730px) {
        .modal-block-div {

        }
    }
    @media screen and (max-width: 730px) {
        .split-line {
            background-color: #008089;
            display: block;
            margin:3px auto 3px auto;
            width: 110px;
            height: 2px;
        }

        .left-product-cart {
            display: block!important;
            width: 100%;
            text-align: center;
            line-height: 20px;
            padding-right: 5px;
            /*border-bottom: #008089 solid 2px;*/
        }

        .right-product-cart {
            display: block!important;
            width: auto;
            text-align: center;
            padding-right: 5px;
            line-height: 20px;
        }
    }
    @media screen and (max-width: 580px) {
        .modalform-container {
            width: 90%;
        }

        .thumbnails {
            width: 100%;
            position: absolute;
            left: 0;
            top: -50px;
        }

            .thumbnails img {
                display: inline-block;
                height: 40px;
                max-width: 70px;
                margin-right: 20px;
            }
    }
</style>