<template>
    <div class="custom-alert" v-if="localMessage.length">
        <b v-if="!localinfo">Please correct the following error(s):</b>
        <ul>
            <li v-for="message in localMessage" v-bind:key="message" v-html="message"></li>
        </ul>
        <div>
            <a>
                <span class="pointer-button" @click="close" >OK</span>
            </a>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'Alert',
        props: {
            info:Boolean,
            messages: Array,
        },
        data() {
            return {
                localMessage: [],
                localinfo:false,
            }
        },
        methods: {
            close() {
                this.localMessage = [];
            },
        },
        watch: {
            messages() {
                this.localMessage = this.messages;
            }
        },
        mounted() {
            this.localMessage = this.messages; //this.$errors;
            this.localinfo = this.info;
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .custom-alert {
        background-color: #d4d3cf;
        border-radius: 2px;
        position: fixed;
        z-index: 999999;
        width: 400px;
        margin-left: -200px;
        left: 50%;
        top: 30%;
        text-align: center;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        padding: 30px;
        line-height:20px;
    }
    .pointer-button {
        cursor: pointer;
        cursor: pointer;
        border: 1px solid;
        padding: 2px 5px 0px;
        margin-top: 10px;
        display: inline-block;
    }


</style>
