<template>
    <div class="login-frame tour-frame">

        <div id="mdiv" class="black-close" @click="onCloseButton">
            <div class="mdiv">
                <div class="md"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h2 class="h2-custom">MOVIE DETAILS</h2>
                        <!--<p>Για να προχωρήσετε θα πρέπει να συμπληρώσετε τα παρακάτω στοιχεία</p>-->
                        <p>Τα πεδία με αστερίσκο (*) είναι υποχρεωτικά</p>
                    </div>
                </div>
                <!--<div class="row">
        <div class="col-sm-12 text-right">
            <div class="frame-button-radio-register">
                <div class="frame-text-individual">
                    <input class="custom-radio" v-model="customerType" name="customerType" type="radio" id="individual" checked="checked" value="individual">
                    <label for="individual" class="text-register-radio"><span class="text-register">INDIVIDUAL</span></label>
                </div>
                <div class="frame-text-individual">
                    <input class="custom-radio" v-model="customerType" name="customerType" type="radio" id="company" value="company">
                    <label for="company" class="text-register-radio"><span class="text-register">COMPANY</span></label>
                </div>
            </div>
        </div>
    </div>-->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <input type="text" class="awsome_input" v-on:keyup.enter="register"
                                   v-model="vat" placeholder="ΑΦΜ ΤΙΜΟΛΟΓΗΣΗΣ" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <span class="asterisk">*</span>
                            <input type="text" class="awsome_input" v-on:keyup.enter="register"
                                   v-model="title" placeholder="ΤΙΤΛΟΣ PROJECT" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container text-align-left">
                            <span class="asterisk">*</span>
                            <datepicker v-model="dateFrom" input-class="wrapper-class" format="dd/MM/yyyy" placeholder="ΗΜ/ΝΙΑ ΠΑΡΑΛΑΒΗΣ"></datepicker>
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container text-align-left">
                            <span class="asterisk">*</span>
                            <datepicker v-model="dateTo" input-class="wrapper-class" format="dd/MM/yyyy" placeholder="ΗΜ/ΝΙΑ ΕΠΙΣΤΡΟΦΗΣ"></datepicker>
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <a href="#">
                            <span @click="send" class="register-send-button">ΑΠΟΘΗΚΕΥΣΗ</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert :messages="errorsLocal" :info="true"></alert>
</template>


<script>
    import axios from 'axios';
    import datepicker from 'vuejs3-datepicker';

    export default {
        name: 'Register',
        props: {
            msg: String
        },
        components: {
            datepicker
        },
        data() {
            return {
                date: new Date(2016, 9, 16),
                typeLiveTour: "0",
                typeLiveTourTime: "0",
                typeLiveTourDate: "0",
                errorsLocal: [],
                tourTimes: [],
                tourDates: []
            }
        },
        methods: {
            onCloseButton() {
                this.$emit('close-clicked')
            },
            send: function () {

                if (this.checkFields()) {
                    //alert(customerType);
                    axios.post('/api/basket/details/', {
                        vat: this.vat,
                        title: this.title,
                        dateFrom: this.dateFrom,
                        dateTo: this.dateTo,
                        tempId: this.getUID()
                    }).then((response) => {
                        console.log(response);
                        if (!response.data.success) {
                            //localStorage.setItem('user', JSON.stringify(response.data));
                            this.errorsLocal = [response.data.errorMessage];

                            //setTimeout(() => {
                            //    this.onCloseButton();
                            //}, "4000");


                        }
                        else {
                            //console.log("Login failed!");
                            //this.errorsLocal = ["Please try again later!"];

                            this.onCloseButton();
                        }
                        //console.log(response.data.id);

                        //var test = JSON.parse(localStorage.getItem('user'));
                        //console.log(test.id);
                        //console.log(localStorage.getItem('user'));

                    })
                }

            },
            checkFields() {
                //if (this.dateTo == undefined) {
                //    this.errorsLocal = ["All fields are required!"];
                //    return false;
                //}

                if (this.title == undefined) {
                    this.errorsLocal = ["Συμπληρωστε τίτλο"];
                }
                //else if (this.vat == undefined) {
                //        this.errorsLocal = ["Συμπληρωστε ΑΦΜ"];
                //}
                else if (this.dateFrom == undefined) {
                    this.errorsLocal = ["Συμπληρωστε ημερομηνια παραλαβής"];
                }
                else if (this.dateTo == undefined) {
                    this.errorsLocal = ["Συμπληρωστε ημερομηνια επιστροφης"];
                    return false;
                }
                //else if (!this.validEmail(this.email)) {
                //    this.errorsLocal = ["Check your email"];
                //}
                else {
                    return true;
                }

            },
            bindData() {
                //this.specialties = [
                //    { id: 1, name: 'A' },
                //    { id: 2, name: 'B' },
                //    { id: 3, name: 'C' }
                //];
                axios.get('/api/tour/getTimes').then((response) => {
                    this.tourTimes = response.data.times;
                    this.tourDates = response.data.dates;
                })
            },
            register1() {
                this.$errors.push('Name required.');
                this.$errors = ['e', 'r', 'd'];
                //this.$refs.Error.test();
                //console.log(this.errors);
                //debugger;
                axios.post('/api/account/register', {
                    password: this.password,
                    username: this.email
                }).then((response) => {
                    console.log(response);
                    if (response.data != "") {
                        localStorage.setItem('user', JSON.stringify(response.data));
                        this.onCloseButton();
                    }
                    else {
                        console.log("Login failed!");
                        this.errorsLocal = ["Login failed!"];
                    }
                    //console.log(response.data.id);

                    //var test = JSON.parse(localStorage.getItem('user'));
                    //console.log(test.id);
                    //console.log(localStorage.getItem('user'));

                })
                //.catch(function (error) {
                //alert(error);
                //});
            }
        },
        mounted() {
            this.bindData();
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .tour-frame {
        top: 15% !important;
        z-index: 9999 !important;
    }
    .input_container select {
        font-size: 34px;
        width: 100%;
    }
    .awsome_input_border {
        height:1px;   
    }
    /* для элемента input c type="radio" */
    .custom-radio {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
        /* для элемента label связанного с .custom-radio */
        .custom-radio + label {
            display: inline-flex;
            align-items: center;
            user-select: none;
        }
            /* создание в label псевдоэлемента  before со следующими стилями */
            .custom-radio + label::before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #adb5bd;
                border-radius: 50%;
                margin-right: 0.5em;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
            }
        /* стили при наведении курсора на радио */
        .custom-radio:not(:disabled):not(:checked) + label:hover::before {
            border-color: #000000;
        }
        /* стили для активной радиокнопки (при нажатии на неё) */
        .custom-radio:not(:disabled):active + label::before {
            background-color: #000000;
            border-color: #000000;
        }
        /* стили для радиокнопки, находящейся в фокусе */
        .custom-radio:focus + label::before {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
        /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
        .custom-radio:focus:not(:checked) + label::before {
            border-color: #80bdff;
        }
        /* стили для радиокнопки, находящейся в состоянии checked */
        .custom-radio:checked + label::before {
            border-color: #000000;
            background-color: #000000;
            /*background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");*/
        }
        /* стили для радиокнопки, находящейся в состоянии disabled */
        .custom-radio:disabled + label::before {
            background-color: #000000;
        }
    .text-register-radio {
        cursor:pointer;
    }
    .frame-button-radio-register {
        margin: 0 auto;
        width: 330px;
    }
    .frame-text-individual {
        width: 150px;
        display: inline-block;
    }
    .frame-text-individual:first-child {
        margin-right:30px;
    }
    .checkbox-group-button {
        float: left;
    }
    .text-register {
        font-size: 2em;
        font-weight:bold;
    }
    .input_container {
        width: 100%;
        position:relative;
    }
        .input_container .asterisk {
            position:absolute;
            right:0;
            top:10px;
            font-size:33px;
            color:red;
        }
    .login-frame {
        background-color: white;
        padding: 60px;
        position: fixed;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -300px;
        width: 600px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .login-frame p, .login-frame h1{
        color:black;
    }
        .login-frame .awsome_input {
            font-size: 2em;
        }
        .login-frame .contact-email {
            font-family: Roboto Condensed;
            font-weight:bold;
        }
    .register-send-button {
        margin-top: 40px;
        border: 1px solid black;
        display: inline-block;
        padding: 15px 40px 10px;
        color: black;
        font-size: 2em;
    }
    .login-header {
        font-size: 2em;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    @media screen and (max-width: 580px) {
        .input_container select{
            font-size: 24px;
        }
        .register-send-button{
            margin-bottom: 0px!important;
        }
        .register-send-button {
            margin-top: 29px;
            padding: 15px 40px 10px;
        }
    }
    @media screen and (max-width: 580px) {
        .tour-frame {
            top: 10%!important;
        }
        .login-frame {
            width: 86%;
            margin-left: -43%;
            font-size:100%;
        }
        .register-send-button {
            margin-bottom:40px;
        }
        .login-header {
            margin-top: 0px;
        }
        .login-frame {
            padding: 30px 60px;
            top: 20%;
        }
            .login-frame .awsome_input {
                font-size: 1.5em;
            }
    }

</style>
