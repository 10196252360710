<template>
    <main>
        <div class="decor-header">
            <h1 class="h1 h1-2">NO WALL. NO LIMITS.</h1>
        </div>
        <div class="content-text">
            <p>Τι χρειάζεστε; Ένα στάβλο ή ένα παιδικό δωμάτιο; Το σαλόνι μίας ηλικιωμένης κυρίας ή μήπως τη μοντέρνα κουζίνα της κόρης της; Ένα εφηβικό δωμάτιο ή ένας χώρο αναμονής σε μία τράπεζα. Στην prop n roll δεν υπάρχει κανένας περιορισμός.</p>
            <p>
                850 τετραγωνικά, δυνατότητα στησίματος παράλληλων décor και ταυτόχρονα 40.000 props βρίσκονται στη διάθεση σας, στο ίδιο κτίριο. Τι άλλο χρειάζεστε;
            </p>
            <p>Η Prop n Roll σας δίνει τη δυνατότητα να κινηματογραφήσετε ή και να φωτογραφίσετε το project σας κάτω από τις ιδανικές συνθήκες, εύκολα και οικονομικά. Έτοιμα décor (κουζίνες, δωμάτια, σαλόνια κλπ) μπορούν να χρησιμοποιηθούν αυτούσια ή να διαμορφωθούν όπως ακριβώς εσείς θέλετε χάρη στην ποικιλία ξεχωριστών δομικών υλικών που διαθέτουμε (πόρτες, παράθυρα, κάγκελα κλπ) ώστε να καλύψετε πλήρως τις ανάγκες του γυρίσματός σας. Και φυσικά με τη δυνατότητα άμεσων αλλαγών στο art direction μέσα από τη μεγαλύτερη συλλογή props στην Ελλάδα.</p>
            <p>Έτσι παραγωγοί και δημιουργοί (σκηνογράφοι/art director, φωτογράφοι) μπορούν για πρώτη φορά να είναι ταυτόχρονα όλοι ικανοποιημένοι. Καθόλου άσχημα.</p>
            <p>*Τα décor μας είναι διαθέσιμα και για μεταφορά εκτός των εγκαταστάσεών μας, για τις ανάγκες του γυρίσματός σας σε εξωτερικό ή εσωτερικό χώρο.</p>
        </div>
        <div class="h1 h1-3"><router-link :to="{ name: 'Decor' }">DECOR</router-link></div>
        <!--<div class="h1 h1-3"><router-link :to="{ name: 'Elements' }">STRUCTURAL ELEMENTS</router-link></div>-->
        <div class="h1 h1-3"><router-link :to="{ name: 'Categories', params: { id: 5657 }, query: { type: '1' }  }">STRUCTURAL ELEMENTS</router-link></div>
        <div class="h1 h1-3"><router-link :to="{ name: 'Wrap' }">IT'S A WRAP</router-link></div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    export default {
        name: 'Roll',
        props: {
            msg: String
        },
        beforeCreate: function () {
            document.body.className = ' white-style yellow-bg';
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .content-text {
        text-align:justify;
    }

</style>
