<!--ESPA-->
<template>
    <!--<header>
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
            <div class="container">
                <a class="navbar-brand">Vue JS Template for .NET 5</a>
                <button class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        aria-label="Toggle navigation"
                        @click="toggle">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
                     v-bind:class="{show: isExpanded}">
                    <ul class="navbar-nav flex-grow">
                        <li class="nav-item">
                            <router-link :to="{ name: 'Home' }" class="nav-link text-dark">Home</router-link>

                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'Counter' }" class="nav-link text-dark">Counter</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'FetchData' }" class="nav-link text-dark">Fetch Data</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>-->
    <div class="espa-mobile"><a href="https://www.propnroll.com/espa.pdf" target="_blank"><img alt="espa" src="../assets/espa.jpg" /></a></div>
    <header class="main-header">
        <div class="logo"><router-link :to="{ name: 'Home' }"><span v-on:click="uncheck"><img alt="propnroll" :src='setLogo()' /></span></router-link></div>
        <div v-show="1==2" class="navigation-sheme">
            <div id="menuToggle">
                <input type="checkbox" id="mobile-menu-check" v-model="checkboxValue" />
                <span class="menu-line"></span>
                <span class="menu-line"></span>
                <span class="menu-line"></span>
                <ul id="menu">
                    <li>
                        <router-link :to="{ name: 'About' }"><span v-on:click="uncheck">About</span></router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Prop' }"><span v-on:click="uncheck">Prop</span></router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Roll' }"><span v-on:click="uncheck">Roll</span></router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Contact' }"><span v-on:click="uncheck">Contact</span></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!--<div class="left-menu">
        <ul>
            <li><a href="https://www.propnroll.com/espa.pdf" target="_blank"><img class="espa" src="../assets/espa.jpg" /></a></li>-->
        <!--<li><span v-if="isLoggedIn"><a @click="logout">Logout</a></span></li>-->
        <!--<li v-if="isLoggedIn"><a @click="userMenu()">{{user.firstname}} &nbsp; <i class="fa fa-user" aria-hidden="true"></i></a></li>
            <li v-if="!isLoggedIn"><a @click="openLogin()"><i class="fa fa-user" aria-hidden="true"></i></a></li>
            <li><a @click="focusInputSearch()"><i class="fa fa-search" aria-hidden="true"></i></a></li>
            <li class="cart-top">
                <a class="shopping-cart" @click="openListItems()"><i class="fa fa-shopping-basket" aria-hidden="true"></i></a>
                <span class="cart-qty-top">{{cartQuantity}}</span>
            </li>
        </ul>
    </div>-->
        <div class="left-menu">
            <ul>
                <li><a href="https://www.propnroll.com/espa.pdf" target="_blank"><img alt="espa" class="espa" src="../assets/espa.jpg" /></a></li>
                <!--<li><span v-if="isLoggedIn"><a @click="logout">Logout</a></span></li>-->
                <li class="cart-top">
                    <router-link :to="{ name: 'Contact' }">
                        <img alt="user" src="../assets/email.svg" class="menu-user white-img" />
                        <img alt="user" src="../assets/email-black.svg" class="menu-user black-img" />
                    </router-link>
                </li>
                <li v-if="isLoggedIn" v-show="$route.params.id != '0'">
                    <a @click="userMenu()">
                        {{user.firstname}} &nbsp;
                        <!--<i class="fa fa-user" aria-hidden="true"></i>-->
                        <img alt="user" src="../assets/user.svg" class="menu-user white-img" />
                        <img alt="user" src="../assets/user-black.svg" class="menu-user black-img" />
                    </a>
                </li>
                <li v-if="!isLoggedIn" v-show="$route.params.id != '0'">
                    <a @click="openLogin()">
                        <!--<i class="fa fa-user" aria-hidden="true"></i>-->
                        <img alt="user" src="../assets/user.svg" class="menu-user white-img" />
                        <img alt="user" src="../assets/user-black.svg" class="menu-user black-img" />
                    </a>
                </li>
                <li v-show="$route.params.id != '0'">
                    <a @click="focusInputSearch()">
                        <img alt="search" src="../assets/search.svg" class="menu-search white-img" />
                        <img alt="search" src="../assets/search-black.svg" class="menu-search black-img" />
                        <!--<i class="fa fa-search" aria-hidden="true"></i>-->
                    </a>
                </li>
                <li class="cart-top" v-show="$route.params.id != '0'">
                    <a class="shopping-cart" @click="openListItems()">
                        <!--<i class="fa fa-shopping-basket" aria-hidden="true"></i>-->
                        <img alt="basket" src="../assets/basket.svg" class="menu-basket white-img" />
                        <img alt="basket" src="../assets/basket-black.svg" class="menu-basket black-img" />
                    </a>
                    <span class="cart-qty-top">{{cartQuantity}}</span>
                </li>
            </ul>
        </div>
    </header>
    <transition name="fade-modal">
        <login v-if="showModal" @register-clicked="openLoginRegister()" @close-clicked="openLogin()" />
    </transition>
    <transition name="fade-modal">
        <register v-if="showModalRegister" @close-clicked="openLoginRegister()" />
    </transition>
    <transition name="fade-modal">
        <items v-if="showListItems" @close-clicked="openListItems()" />
    </transition>
    <transition name="fade-modal">
        <user-menu v-if="showUserMenu" @close-clicked="userMenu()" />
    </transition>
    <transition name="fade-modal">
        <search v-if="showSearch" @close-clicked="focusInputSearch()" />
    </transition>
    <!--<transition name="fade-modal">
        <div class="search-frame" v-if="showSearch">
            <div class="search-frame-buttons">
                <div class="search-buttons">
                    <div id="mdiv" class="white-close no-border search-close" @click="showSearch = false">
                        <div class="mdiv">
                            <div class="md"></div>
                        </div>
                    </div>
                    <input class="search-input" ref="searchInput" placeholder="SEARCH" type="text" />
                    <div class="text-align-right">
                        <span @click="focusInputSearch()" class="button-home">
                            <img src="../assets/narrow.svg" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </transition>-->

</template>


<script>

    //https://www.npmjs.com/package/@trevoreyre/autocomplete-vue#search

    import login from './Login.vue';
    import userMenu from './UserMenu.vue';
    import register from './Register.vue';
    import items from './ListItems.vue';
    import { mapGetters } from "vuex";
    import search from './Search.vue'

    export default {
        name: "NavMenu",
        data() {
            return {
                isExpanded: false,
                checkboxValue: false,
                showModal: false,
                showModalRegister: false,
                showListItems: false,
                showSearch: false,
                showUserMenu: false
            }
        },
        components: {
            login,
            register,
            items,
            userMenu,
            search
        },
        methods: {
            focusInputSearch() {
                this.showSearch = !this.showSearch;
                //this.showSearch = true;
                //this.timer = setTimeout(() => {
                //    this.$refs.searchInput.focus();
                //}, 200);
            },
            //openPdf() {
            //    window.open('espa.pdf', '_blank').focus();
            //},
            //getPdf() {
            //    return require('../assets/espa.pdf');
            //},
            collapse() {
                this.isExpanded = false;
            },
            openLogin() {
                //this.showModal = true;
                this.showModal = !this.showModal;
            },
            userMenu() {
                //alert('menu');
                //this.showModal = true;
                this.showUserMenu = !this.showUserMenu;
            },
            openListItems() {
                //this.showModal = true;
                this.showListItems = !this.showListItems;
            },
            openLoginRegister() {
                this.showModal = false;
                this.showModalRegister = !this.showModalRegister;
            },
            toggle() {
                this.isExpanded = !this.isExpanded;
            },
            uncheck() {
                this.checkboxValue = false;
            },
            setLogo() {
                //this.whiteLogo = false;
                //console.log(this.$route.name);
                if (this.$route.name === 'Contact' || this.$route.name === 'Wrap' || this.$route.name === 'ElementItem'
                    || this.$route.name === 'Decor' || this.$route.name === 'DecorItem' || this.$route.name === 'Product'
                    || this.$route.name === 'Cart' || this.$route.name === 'Partner' || this.$route.name === 'PartnerRent') {
                    return require('../assets/' + 'logo-black.svg')
                }
                else {
                    return require('../assets/' + 'logo-white.svg')
                }

            },
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        //this.$router.push('/login')
                        this.$router.push('/')
                    })
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
            ...mapGetters([
                "cartQuantity"
            ]),
            isLoggedIn: function () { return this.$store.getters.isLoggedIn },
            user: function () { return this.$store.getters.user }
        },
        //mounted() {
        //    console.log(this.$globalValue);
        //},
        //watch: {
        //    '$basketCount': function (newvalue) {
        //        alert(newvalue);
        //        alert(this.$basketCount);
        //    },
        //},
        created() {
            this.$store.dispatch('getCartItems', this.getUID());
        }
    }
</script>

<style>
    .white-img, .black-img {
        display: none;
    }

    .white-style .social i, .white-style .left-menu i {
        color: #fff;
    }

    .black-style .social i, .black-style .left-menu i {
        color: #000;
    }

    .white-style .social i, .white-style .left-menu .white-img {
        display: inline-block;
    }

    .black-style .social i, .black-style .left-menu .black-img {
        display: inline-block;
    }

    .menu-user {
        width: 20px;
        height: 20px;
    }

    .menu-search {
        width: 20px;
        height: 20px;
    }

    .menu-basket {
        width: 20px;
        height: 20px;
    }
    /*ΕΣΠΑ*/
    #menu li {
        width: 25% !important;
    }
    /*Τελος ΕΣΠΑ*/
    .espa {
        height:30px;
        vertical-align:middle;
    }
    .espa-mobile {
        display:none;
    }
    .cart-top {
        position: relative;
    }

    .cart-qty-top {
        display: inline-block;
        color: white;
        background-color: black;
        border-radius: 48%;
        padding: 3px 6px;
        font-size: 9px;
        top: -11px;
        position: absolute;
        right: -8px;
    }
    
    .fade-modal-enter-active, .fade-modal-leave-active {
        transition: opacity .3s;
    }

    .fade-modal-enter-from, .fade-modal-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }

    .left-menu a {
        cursor: pointer;
    }

        .left-menu a:not([href]):not([class]) {
            color: black;
        }

    .white-style .left-menu a:not([href]):not([class]) {
        color: white;
    }


    /** menu **/

    #menuToggle {
        position: absolute;
        top: 25px;
        left: 50%;
        margin-left: -18px;
        z-index: 1000;
        -webkit-user-select: none;
        user-select: none;
    }

        #menuToggle a {
            text-decoration: none;
            /*color: #ffc500;*/
            color: #017f8a;
            transition: color 0.3s ease;
            font-size: 35px;
            text-transform: uppercase;
            position: relative;
            letter-spacing: 3px;
        }

            #menuToggle a.router-link-exact-active {
                color: #017f8a;
            }

            #menuToggle a:hover {
                /*color: #017f8a;*/
                color: #ffc500;
            }

                #menuToggle a:hover::after {
                    content: "";
                    border-bottom: 4px solid #017f8a;
                    position: absolute;
                    display: block;
                    left: 0px;
                    width: 100%;
                    bottom: -10px;
                }


        #menuToggle input {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
            cursor: pointer;
            opacity: 0; /* hide this */
            z-index: 2; /* and place it over the hamburger */
            -webkit-touch-callout: none;
        }

    /*
 * Just a quick hamburger
 */
    .black-style #menuToggle span.menu-line {
        background: #000;
    }

    .white-style #menuToggle span.menu-line {
        background: #fff;
    }

    #menuToggle span.menu-line {
        display: block;
        width: 33px;
        height: 1px;
        margin-bottom: 5px;
        position: relative;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
    }

        #menuToggle span.menu-line:first-child {
            transform-origin: 0% 0%;
        }

        #menuToggle span.menu-line:nth-last-child(2) {
            transform-origin: 0% 100%;
        }

    /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
    #menuToggle input:checked ~ span.menu-line {
        opacity: 1;
        /*transform: rotate(45deg) translate(-2px, -1px);*/
        transform: rotate(23deg) translate(-4px, -3px);
        background: #000;
    }

        /*
 * But let's hide the middle one.
 */
        #menuToggle input:checked ~ span.menu-line:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        /*
 * Ohyeah and the last one should go the other direction
 */
        #menuToggle input:checked ~ span.menu-line:nth-last-child(2) {
            transform: rotate(-24deg) translate(0, -5px);
        }

    /*
 * Make this absolute positioned
 * at the top left of the screen
 */

    #menu {
        position: fixed;
        width: 80%;
        left: 10%;
        top: 0px;
        padding: 50px;
        padding-top: 125px;
        background: #fff;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(0, -105%);
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    .black-style #menu {
        box-shadow: -1px 10px 12px -6px rgba(132,132,132,0.75);
        -webkit-box-shadow: -1px 10px 12px -6px rgba(132,132,132,0.75);
        -moz-box-shadow: -1px 10px 12px -6px rgba(132,132,132,0.75);
    }

    #menu li {
        display: inline-block;
        text-align: center;
        width: 24%;
        margin: 17% 0 21% 0;
        font-weight: 500;
    }

    /*
 * And let's slide it in from the left
 */
    #menuToggle input:checked ~ ul {
        transform: none;
    }

    .menu-mobile-ul {
        position: relative;
    }

    menuToggle

    .menu-mobile-ul ul {
        display: none;
    }
    /*.menu-mobile-ul:after {
    content: ">";
    position: absolute;
    right:0px;
    top:10px;
    }*/
    .mobile-menu-narrow {
        position: absolute;
        right: 0px;
        top: 15px;
    }

    .menu-mobile-ul .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }

    .menu-mobile-ul .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .menu-mobile-ul .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    .menu-mobile-ul .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .menu-mobile-ul .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    /** end menu **/


    @media screen and (max-width: 580px) {
        .espa {
            display:none;   
        }
        .espa-mobile {
            display: block;
            text-align:right;
            width:100%;
        }
        .espa-mobile img{
            width:140px;
        }
    }

</style>
