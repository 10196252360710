<template>
    <main>
        <h1 class="h1">CONTACT</h1>
        <div class="contact-image">
            <!--<img src="../assets/contact-image.svg" />-->
            <a href="https://www.google.com/maps/place/props+market/@37.9965173,23.8475719,17z/data=!4m13!1m7!3m6!1s0x14a1998fa1dccceb:0x3ba65f227aa52510!2zzpHOuM6uzr3Osc-CIM6gzrHOu867zqzOtM6_z4IgMSwgzpPOu8-FzrrOrCDOnc61z4HOrCAxNTMgNTQsIM6VzrvOu86szrTOsQ!3b1!8m2!3d37.9965173!4d23.8497606!3m4!1s0x14a19985c1990201:0x542b0fc2acde0283!8m2!3d37.9961178!4d23.8512325" target="_blank">
                <img src="../assets/find_us_1.gif" />
            </a>
        </div>
        <div class="content-frame">
            <div class="row">
                <div class="col-sm-8">
                    <div class="input_container">
                        <input type="text" class="awsome_input" placeholder="NAME/ SURNAME *" v-model="name" />
                        <span class="awsome_input_border" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="input_container">
                        <input type="text" class="awsome_input" placeholder="E-MAIL *" v-model="email" />
                        <span class="awsome_input_border" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="input_container">
                        <input type="text" class="awsome_input" placeholder="COMPANY *" v-model="company" />
                        <span class="awsome_input_border" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="input_container">
                        <input type="text" class="awsome_input" placeholder="TEL *" v-model="phone" />
                        <span class="awsome_input_border" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="input_container">
                        <input type="text" class="awsome_input" placeholder="YOUR MESSAGE *" v-model="message" />
                        <span class="awsome_input_border" />
                    </div>
                </div>
                <div class="col-sm-4">
                </div>
            </div>
            <div class="row agreement-row">
                <div class="col-sm-8">
                    <div class="row">
                        <div class="col-sm-2">
                            <div class="checkbox-group-button">
                                <input type="checkbox" id="inputOne" v-model="agree" />
                                <label for="inputOne"></label>
                            </div>
                        </div>
                        <div class="col-sm-10">
                            <div class="contact-text-4">
                                <p>I AGREE</p>
                                <p><br /></p>
                                <p>GDPR COMPLIANCE</p>
                                <p>BY COMPLETING AND SENDING YOUR DATA, YOU AGREE</p>
                                <p>TO THE PRIVACY POLICY.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <a @click="sendMessage">
                        <span class="contact-send-button">SEND</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="contact-bottom-text">
            <div class="contact-text-1">MORE PROPS EVERYDAY</div>
            <div class="contact-text-2">DON'T MISS THEM OUT!</div>
            <div class="row contact-social-frame">
                <div class="col-sm-11">
                    <div class="contact-text-3">
                        <p>PALLADOS ATHINAS 10 GLYKA NERA</p>
                        <p>ATHENS, GREECE 15354</p>
                        <p>+30 213 0332316</p>
                    </div>
                </div>
                <div class="col-sm-1 white-style">
                    <social></social>
                </div>
            </div>
        </div>
        <div class="copyright">{{new Date().getFullYear()}} copyright. All right reserved</div>
    </main>
    <alert :messages="errorsLocal" :info="true"></alert>
</template>


<script>
    import axios from 'axios'
    export default {
        name: 'Contact',
        props: {
            msg: String
        },
        data() {
            return {
                errorsLocal: []
            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            checkFields() {
                if (!this.name || !this.email || !this.phone || !this.message || !this.company) {
                    this.errorsLocal = ["All fields are required!"];
                    return false;
                }
                else if (!this.validEmail(this.email)) {
                    this.errorsLocal = ["Check your email"];
                }
                else if (!this.agree) {
                    this.errorsLocal = ["You must agree to the terms of this Agreement!"];
                }
                else {
                    return true;
                }

            },
            sendMessage() {
                if (this.checkFields()) {
                    let data = {
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        message: this.message,
                        company: this.company
                    }

                    axios.post('/api/contact', data).then((response) => {
                        //commit('UPDATE_CART_ITEMS', response.data)
                        //console.log(response.data);
                        if (response.data.result == 'ok') {
                            this.errorsLocal = ["Your message has been sent"];
                        }
                    });
                }

            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .contact-send-button {
        cursor:pointer;
    }
    @media screen and (max-width: 580px) {
        .contact-text-1 {
            font-size: 2em;
            margin-bottom:20px;
        }
        .contact-text-2 {
            font-size: 3em;
        }
        .contact-text-3 {
            font-size: 1em;
            line-height:1em;
        }
            .contact-text-3 p {
                line-height: 8px;
            }
        .social {
            position: absolute;
            right: 0px;
            bottom: -10px;
            width:40px;
            left:unset;
        }
    }
</style>
