<template>
    <main>
        <h1 class="h1">IT’S A WRAP</h1>
        <div class="content-text content-text-wrap">
            <p>
                Τα φώτα σβήνουν, οι πρωταγωνιστές αποχωρούν, οι ηλεκτρολόγοι μαζεύουν, οι βοηθοί ξεστήνουν και ο νόμος του Μέρφι επιβεβαιώνεται στο επόμενο δεκάλεπτο. “Το καλύτερο σενάριο για τους πελάτες είναι το σενάριο του ανταγωνιστή”. Έτσι, η κουζίνα που μόλις διαλύσατε παίζει σχεδόν αυτούσια στο νέο σενάριο που μόλις λάβατε.
            </p>
            <p>
                Μπορείτε να αποφύγετε παρόμοιες ατυχίες απλά αποθηκεύοντας και τα décor εκτός από τα props σας στην Prop n Roll. Έτσι, χωρίς κόστος, θα τα έχετε διαθέσιμα για την επόμενη φορά που θα τα χρειαστείτε. Αν πάλι δεν σας ενδιαφέρει η αποθήκευσή τους μπορείτε να έρθετε σε επικοινωνία μαζί μας. Μπορεί να μας ενδιαφέρει η αγορά τους.
            </p>
        </div>
    </main>
    <social socialClass="social-right fixed"></social>
</template>


<script>
    export default {
        name: 'Wrap',
        props: {
            msg: String
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .content-text-wrap {
        margin-top:10%;
    }
</style>
