<template>
    <div class="basket-list-items">
        <div class="row">
            <div class="col-sm-12">
                <div class="scroll-classic">
                    <div class="cart-item" v-for="cartItem in cartItems.basketItems" :key="cartItem.productId">
                        <CartListItem :cartItem="cartItem" />
                        <!--{{cartItem.qty}} - {{cartItem.price}}-->
                    </div>
                </div>
            </div>
        </div>
        <div class="button-view-basket-frame">
            <!--<span class="button-view-basket" @click="viewBasket"> <router-link :to="{ name: 'Cart' }" @click="onCloseButton" class="nav-link text-white">View Basket</router-link></span>-->
            <span class="button-view-basket" @click="viewBasket"> View Basket</span>
        </div>
    </div>


</template>


<script>
    import { mapGetters, mapActions } from "vuex";
    import CartListItem from "./ListItem";

    export default {
        name: 'ListItems',
        components: {
            CartListItem
        },
        methods: {
            viewBasket() {
                this.onCloseButton();
                this.$router.push('/cart');
            },
            onCloseButton() {
                this.$emit('close-clicked')
            },
            ...mapActions(["removeAllCartItems"]),
        },
        computed: {
            ...mapGetters(["cartItems", "cartTotal", "cartQuantity"]),
        },
        created() {
            //this.$store.dispatch("getCartItems");
            this.$store.dispatch('getCartItems', this.getUID());
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .button-view-basket-frame {
        margin-top:40px;
    }
    .button-view-basket {
        width: 100%;
        text-align: center;
        display: block;
        text-transform: uppercase;
        padding: 10px 0;
        color: white;
        background-color: black;
        cursor:pointer;
    }
    .basket-list-items {
        border: 2px solid black;
        background-color: white;
        padding: 20px;
        position: fixed;
        z-index: 999;
        top: 60px;
        right: 46px;
        margin-left: -400px;
        width: 500px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
/*    .scroll-classic {
        max-height: 500px!important;
    }*/
    .text-register {
        font-size: 2em;
    }
    .input_container {
        width: 100%;
    }
    .login-frame {
        background-color: white;
        padding: 60px;
        position: fixed;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -300px;
        width: 600px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .login-frame p, .login-frame h1{
        color:black;
    }
        .login-frame .awsome_input {
            font-size: 2em;
        }
        .login-frame .contact-email {
            font-family: Roboto Condensed;
            font-weight:bold;
        }
    .register-send-button {
        margin-top: 40px;
        border: 1px solid black;
        display: inline-block;
        padding: 15px 40px 10px;
        color: black;
        font-size: 2em;
    }
    .login-header {
        font-size: 2em;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    @media screen and (max-width: 580px) {
        .login-frame {
            width: 86%;
            margin-left: -43%;
            font-size:100%;
        }
        .register-send-button {
            margin-bottom:40px;
        }
        .login-header {
            margin-top: 0px;
        }
        .login-frame {
            padding: 30px 60px;
            top: 20%;
        }
            .login-frame .awsome_input {
                font-size: 1.5em;
            }
        .basket-list-items {
            border: 2px solid black;
            background-color: white;
            padding: 20px;
            position: fixed;
            z-index: 999;
            top: 60px;
            right: 10px;
            margin-left: -400px;
            width: 96%;
        }
    }
</style>
