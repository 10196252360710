<template>
    <div class="logo"><img alt="pronroll" src='../assets/logo-black.svg' /></div>
    <div class="frame-simpleuser-login">
        <div class="row" v-show="!showLogin">
            <div class="col-sm-12">
                <!--<div class="row" v-show="registered">
        <div class="col-sm-12 text-alert">
            <p>Account had been created. We will send you information to your email account. </p>
        </div>
    </div>-->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="register_firstname_label" for="register_firstname">FIRST NAME*</label>
                            <input type="text" id="register_firstname" class="awsome_input" v-model="register_firstname" placeholder="FIRST NAME*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="register_lastname_label" for="register_lastname">LAST NAME*</label>
                            <input type="text" class="awsome_input" id="register_lastname" v-model="register_lastname" placeholder="LAST NAME*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="register_email_email" for="register_email">E-MAIL*</label>
                            <input type="text" class="awsome_input" id="register_email" v-model="register_email" placeholder="E-MAIL*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none"  id="register_phone_label" for="register_phone" >PHONE*</label>
                            <input type="text" class="awsome_input" id="register_phone" v-model="register_phone" placeholder="PHONE*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="profession_label" for="profession">SPECIALTY</label>
                            <!--<select name="profession" id="profession" v-model="profession">
                                <option value="0" selected="selected">--Select SPECIALTY--</option>
                                <option value="ASSISTANT ART DIRECTOR">ASSISTANT ART DIRECTOR</option>
                                <option value="ART DIRECTOR">ART DIRECTOR</option>
                                <option value="PROPS MASTER">PROPS MASTER</option>
                                <option value="PHOTOGRAPHER">PHOTOGRAPHER</option>
                                <option value="PRODUCER">PRODUCER</option>
                                <option value="PRODUCTION MANAGER">PRODUCTION MANAGER</option>
                                <option value="PRODUCTION ASSISTANT">PRODUCTION ASSISTANT</option>
                                <option value="FOOD STYLIST">FOOD STYLIST</option>
                                <option value="OTHER">OTHER</option>
                            </select>-->
                            <select v-model="specialtySelected">
                                <option value="0" selected="selected">--Select SPECIALTY--</option>
                                <option v-for="specialty in specialties"
                                        v-bind:key="specialty"
                                        v-bind:value="{ id: specialty.id, text: specialty.name }">
                                    {{ specialty.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!--<div class="row">
        <div class="col-sm-12">
            <div class="input_container">
                <input type="password" class="awsome_input" v-model="password" placeholder="PASSWORD*" />
                <span class="awsome_input_border" />
            </div>
        </div>
    </div>-->
                <div class="row">
                    <div class="col-sm-6">
                        <a href="#">
                            <span @click="register" class="register-send-button">REGISTER</span>
                        </a>
                    </div>
                    <div class="col-sm-6">
                        <a href="#">
                            <span @click="showHideForm" class="register-send-button">LOGIN</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-show="showLogin">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input_container">
                            <label class="display-none" id="password_label" for="password">PASSWORD</label>
                            <input type="text" class="awsome_input" id="password" v-model="password" placeholder="PASSWORD*" />
                            <span class="awsome_input_border" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <a href="#">
                            <span @click="login" class="register-send-button">LOG IN</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12 register-frame">
                        <a href="#" @click="showHideForm">
                            <span class="register-send-button button-new-account">CREATE NEW ACCOUNT</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <alert :messages="errorsLocal" :info="true"></alert>
</template>


<script>

    import axios from 'axios'

    export default {
        name: 'NotAllow',
        props: {
            msg: String
        },
        data() {
            return {
                showLogin: true,
                profession: "0",
                errorsLocal: [],
                specialties: [],
                specialtySelected: "0"
            }
        }, methods: {
            register() {

                //alert(this.specialtySelected.text);
                //alert(this.specialtySelected.id);


                if (this.checkFields()) {
                    axios.post('/api/user/', {
                        firstname: this.register_firstname,
                        lastname: this.register_lastname,
                        phone: this.register_phone,
                        email: this.register_email,
                        profession: this.specialtySelected.text,
                        profession_id: this.specialtySelected.id
                    }).then((response) => {
                        this.errorsLocal = [response.data.message];
                        //console.log(response);
                        //if (response.data != "") {
                        //    //console.log("Account created!");
                        //    this.errorsLocal =  ['Account had been created. We will send you information to your email account.'];
                        //}
                        //else {
                        //    console.log("Login failed!");
                        //    //this.errorsLocal = ["Login failed!"];
                        //}
                    })
                }
            },
            bindSpecialty() {
                //this.specialties = [
                //    { id: 1, name: 'A' },
                //    { id: 2, name: 'B' },
                //    { id: 3, name: 'C' }
                //];
                axios.get('/api/user/specialty').then((response) => {
                    this.specialties = response.data;
                })
            },
            showHideForm() {
                this.showLogin = !this.showLogin;
            },
            checkFields() {

                if (!this.register_lastname || !this.register_firstname || !this.register_email || !this.register_phone) {
                    this.errorsLocal = ["All fields are required!"];
                    return false;
                }
                else if (!this.validEmail(this.register_email)) {
                    this.errorsLocal = ["Check your email"];
                }
                else if (this.specialtySelected == "0") {
                    this.errorsLocal = ["Select profession"];
                }
                else
                {
                    return true;
                }

            },
            checkFieldsLogin() {
                if (!this.password) {
                    this.errorsLocal = ["All fields are required!"];
                    return false;
                }
                else {
                    return true;
                }

            },
            login() {
                if (this.checkFieldsLogin()) {
                    axios.post('/api/user/login', {
                        password: this.password
                    }).then((response) => {
                        console.log(response);
                        if (response.data != "") {
                            if (response.data.id != 0) {
                                localStorage.setItem('simpleUser', response.data.email);
                                this.$router.push({ name: 'Home', query: { menu: '1' } });
                            }
                        }
                        else {
                            this.errorsLocal = ["Login failed!"];
                            //console.log("Login failed!");
                            //this.errorsLocal = ["Login failed!"];
                        }
                    })
                //if (this.password == 'parkot3@gmail.com') {

                //}
                }

            }
        },
        mounted() {
            if (localStorage.getItem("simpleUser") !== null) {
                this.$router.push({ name: 'Home'});
            }
            this.bindSpecialty();
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .input_container {
        width: 100%;
    }
        .input_container select {
            width: 100%;
            font-size: 47px;
        }
        .text-alert {
            color: red;
        }
    .frame-simpleuser-login {
        width: 600px;
        margin: 10% auto;
        padding:30px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .content-text-wrap {
        margin-top: 10%;
    }
    .login-frame {
        background-color: white;
        padding: 60px;
        position: fixed;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -400px;
        width: 800px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }

        .login-frame p, .login-frame h1 {
            color: black;
        }

        .login-frame .awsome_input {
            font-size: 2em;
        }

        .login-frame .contact-email {
            font-family: Roboto Condensed;
            font-weight: bold;
        }

    .register-send-button {
        margin-top: 40px;
        border: 1px solid black;
        display: inline-block;
        padding: 15px 40px 10px;
        color: black;
        font-size: 2em;
    }

    .login-header {
        font-size: 2em;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .button-new-account {
        margin-top: 0px;
    }

    @media screen and (max-width: 580px) {
        .login-frame {
            width: 86%;
            margin-left: -43%;
            font-size: 100%;
        }

        .register-send-button {
            margin-bottom: 0px;
        }

        .login-header {
            margin-top: 0px;
        }

        .login-frame {
            padding: 30px 60px;
            top: 20%;
        }

            .login-frame .awsome_input {
                font-size: 1.5em;
            }
        .frame-simpleuser-login {
            width: 80%;
            margin: 15% auto;
        }
        .register-frame {
            margin-top:40px;
        }
    }
</style>
