//https://www.digitalocean.com/community/tutorials/handling-authentication-in-vue-using-vuex
//https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/#top



import axios from 'axios';

const state = {
    status: '',
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || {}
}

const mutations = {
    //UPDATE_CART_ITEMS(state, payload) {
    //    state.cartItems = payload;
    //}
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, { token, user }) {
        state.status = 'success'
        state.token = token
        state.user = user
        //state.userJson = user
        //state.user = JSON.stringify(user)
        //state.user = { token: 14, title: "Κώστας" }
        //console.log('4', token)
        //console.log('3', state.user)
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
        state.user = ''
    },
}

const actions = {
    login({ commit }, user) {
        //axios.get('/api/cart/' + id).then((response) => {
        //    commit('UPDATE_CART_ITEMS', response.data)
        //});
        return new Promise((resolve, reject) => {
            commit('auth_request')
            //axios({ url: '/api/account/login', data: user, method: 'POST' })
            axios({ url: '/api/account/login', data:{
                password: user.password,
                username: user.email
            }, method: 'POST' })
                .then(resp => {
                    if (resp.data != '') {
                        if (resp.data.id == 0) {
                            resolve(resp);
                        }
                        else {
                            const token = resp.data.token
                            const user = resp.data
                            //state.user = user;
                            //console.log('1', state.user);
                            //console.log('2', resp.data);
                            //console.log('3', resp.data.token);
                            localStorage.setItem('token', token)
                            localStorage.setItem('user', JSON.stringify(user))
                            //JSON.stringify(user)
                            //console.log('5', localStorage.getItem('user'))
                            axios.defaults.headers.common['Authorization'] = token
                            commit('auth_success', { token, user })
                            resolve(resp)
                        }
                    }
                })
                .catch(err => {
                    //console.log('logout');
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    reject(err)
                })
        })
    },
    register({ commit }, user) {
        axios.post('/api/account/register', user).then((response) => {
            commit('auth_request');
            console.log(response.data);
            //commit('UPDATE_CART_ITEMS', response.data)
        });
    },
    logout({ commit }) {
        return new Promise((resolve) => {
            commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    }
}

const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user
    //cartItems: state => state.cartItems,
    //cartTotal: state => {
    //    return state.cartItems.reduce((acc, cartItem) => {
    //        return (cartItem.qty * cartItem.price) + acc;
    //    }, 0).toFixed(2);
    //},
    //cartQuantity: state => {
    //    return state.cartItems.reduce((acc, cartItem) => {
    //        return cartItem.qty + acc;
    //    }, 0);
    //}
}

const acountModule = {
    state,
    mutations,
    actions,
    getters
}
export default acountModule;